/**
 * 获取金额显示的样式类名
 * @param {number} amount 金额
 * @returns {string} 样式类名
 */
export const getAmountClass = (amount) => {
  const num = Number(amount)
  if (num === 0) return 'amount-zero'
  if (num <= 10) return 'amount-level-1'
  if (num <= 20) return 'amount-level-2'
  if (num <= 30) return 'amount-level-3'
  if (num <= 40) return 'amount-level-4'
  if (num <= 50) return 'amount-level-5'
  if (num <= 60) return 'amount-level-6'
  if (num <= 70) return 'amount-level-7'
  if (num <= 80) return 'amount-level-8'
  if (num <= 90) return 'amount-level-9'
  return 'amount-level-10'
}

/**
 * 格式化金额显示
 * @param {number} amount 金额
 * @returns {string} 格式化后的金额
 */
export const formatAmount = (amount) => {
  return Number(amount).toFixed(2)
} 