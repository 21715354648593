<template>
  <div class="order-management">
    <el-card class="mb-4">
      <div class="top-bar">
        <div class="search-area">
          <el-form :inline="true" :model="searchForm" class="search-form">
            <el-form-item label="用户ID">
              <el-input v-model="searchForm.userId" placeholder="用户ID"></el-input>
            </el-form-item>
            <el-form-item label="订单状态">
              <el-select v-model="searchForm.status" placeholder="订单状态">
                <el-option v-for="status in orderStatusOptions" :key="status.value" :label="status.label" :value="status.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="支付方式">
              <el-select v-model="searchForm.paymentMethod" placeholder="支付方式">
                <el-option v-for="method in paymentMethodOptions" :key="method.value" :label="method.label" :value="method.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="时间范围">
              <el-date-picker
                v-model="dateRange"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="x"
                @change="handleDateRangeChange"
              ></el-date-picker>
            </el-form-item>
          </el-form>
        </div>
        <div class="button-area">
          <el-button type="primary" @click="searchOrders">查询</el-button>
          <el-button @click="resetSearch">重置</el-button>
        </div>
      </div>
    </el-card>

    <el-card>
      <el-table :data="orders" style="width: 100%" v-loading="loading">
        <el-table-column prop="id" label="订单ID" width="220"></el-table-column>
        <el-table-column prop="user_id" label="用户ID" width="220"></el-table-column>
        <el-table-column label="会员计划">
          <template #default="scope">
            <div>
              <div>{{ scope.row.plan?.display_name || '未知计划' }}</div>
              <div class="text-muted">{{ scope.row.plan?.description || '' }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="金额">
          <template #default="scope">
            <div>
              <div>{{ scope.row.payable_amount }} {{ scope.row.currency }}</div>
              <div v-if="scope.row.discount_amount > 0" class="text-muted">
                原价: {{ scope.row.original_amount }} {{ scope.row.currency }}
              </div>
              <div v-if="scope.row.coupon_amount > 0" class="text-muted">
                优惠: {{ scope.row.coupon_amount }} {{ scope.row.currency }}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="支付方式">
          <template #default="scope">
            {{ getPaymentMethodName(scope.row.payment_method) }}
          </template>
        </el-table-column>
        <el-table-column label="订单状态">
          <template #default="scope">
            <el-tag :type="getOrderStatusType(scope.row.status)">
              {{ getOrderStatusName(scope.row.status) }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column label="创建时间">
          <template #default="scope">
            {{ formatDate(scope.row.created_at) }}
          </template>
        </el-table-column>
        <el-table-column label="支付时间">
          <template #default="scope">
            {{ scope.row.paid_at ? formatDate(scope.row.paid_at) : '未支付' }}
          </template>
        </el-table-column>
        <el-table-column prop="subscription_id" label="订阅ID" width="220" show-overflow-tooltip>
          <template #default="scope">
            <el-link 
              v-if="scope.row.subscription_id" 
              type="primary" 
              @click="viewSubscription(scope.row.subscription_id)">
              {{ scope.row.subscription_id }}
            </el-link>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="200">
          <template #default="scope">
            <el-button size="small" type="primary" @click="viewOrderDetail(scope.row)">详情</el-button>
            <el-button 
              size="small" 
              type="warning" 
              @click="updateOrderStatus(scope.row)"
              v-if="canUpdateOrderStatus(scope.row.status)"
            >
              更新状态
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <div class="pagination-container">
        <el-pagination
          v-model:current-page="currentPage"
          v-model:page-size="pageSize"
          :total="total"
          :page-sizes="[10, 20, 50, 100]"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </el-card>

    <!-- 订单详情对话框 -->
    <el-dialog title="订单详情" v-model="detailDialogVisible" width="70%">
      <div v-if="currentOrder" class="order-detail">
        <el-descriptions :column="2" border>
          <el-descriptions-item label="订单ID">{{ currentOrder.id }}</el-descriptions-item>
          <el-descriptions-item label="用户ID">{{ currentOrder.user_id }}</el-descriptions-item>
          <el-descriptions-item label="会员计划">{{ currentOrder.plan?.display_name || '未知计划' }}</el-descriptions-item>
          <el-descriptions-item label="计划描述">{{ currentOrder.plan?.description || '无描述' }}</el-descriptions-item>
          <el-descriptions-item label="订单状态">
            <el-tag :type="getOrderStatusType(currentOrder.status)">
              {{ getOrderStatusName(currentOrder.status) }}
            </el-tag>
          </el-descriptions-item>
          <el-descriptions-item label="支付方式">{{ getPaymentMethodName(currentOrder.payment_method) }}</el-descriptions-item>
          <el-descriptions-item label="原始金额">{{ currentOrder.original_amount }} {{ currentOrder.currency }}</el-descriptions-item>
          <el-descriptions-item label="折扣金额">{{ currentOrder.discount_amount }} {{ currentOrder.currency }}</el-descriptions-item>
          <el-descriptions-item label="优惠券金额">{{ currentOrder.coupon_amount }} {{ currentOrder.currency }}</el-descriptions-item>
          <el-descriptions-item label="应付金额">{{ currentOrder.payable_amount }} {{ currentOrder.currency }}</el-descriptions-item>
          <el-descriptions-item label="创建时间">{{ formatDate(currentOrder.created_at) }}</el-descriptions-item>
          <el-descriptions-item label="支付时间">{{ currentOrder.paid_at ? formatDate(currentOrder.paid_at) : '未支付' }}</el-descriptions-item>
          <el-descriptions-item label="过期时间">{{ currentOrder.expired_at ? formatDate(currentOrder.expired_at) : '无' }}</el-descriptions-item>
          <el-descriptions-item label="客户端类型">{{ currentOrder.client_type }}</el-descriptions-item>
          <el-descriptions-item label="支付ID">{{ currentOrder.payment_id || '无' }}</el-descriptions-item>
          <el-descriptions-item label="备注">{{ currentOrder.remark || '无' }}</el-descriptions-item>
          <el-descriptions-item label="管理员备注">{{ currentOrder.admin_note || '无' }}</el-descriptions-item>
        </el-descriptions>

        <div v-if="currentOrder.subscription" class="subscription-info mt-4">
          <h3>订阅信息</h3>
          <el-descriptions :column="2" border>
            <el-descriptions-item label="订阅ID">{{ currentOrder.subscription.id }}</el-descriptions-item>
            <el-descriptions-item label="订阅名称">{{ currentOrder.subscription.display_name }}</el-descriptions-item>
            <el-descriptions-item label="订阅状态">{{ currentOrder.subscription.status }}</el-descriptions-item>
            <el-descriptions-item label="订阅周期">{{ currentOrder.subscription.period }}</el-descriptions-item>
            <el-descriptions-item label="开始时间">{{ formatDate(currentOrder.subscription.start_time) }}</el-descriptions-item>
            <el-descriptions-item label="结束时间">{{ formatDate(currentOrder.subscription.end_time) }}</el-descriptions-item>
            <el-descriptions-item label="自动续费">{{ currentOrder.subscription.auto_renew ? '是' : '否' }}</el-descriptions-item>
            <el-descriptions-item label="下次扣费时间">
              {{ currentOrder.subscription.next_bill_date ? formatDate(currentOrder.subscription.next_bill_date) : '无' }}
            </el-descriptions-item>
          </el-descriptions>
        </div>

        <div v-if="currentOrder.payment" class="payment-info mt-4">
          <h3>支付信息</h3>
          <el-descriptions :column="2" border>
            <el-descriptions-item label="支付ID">{{ currentOrder.payment.id }}</el-descriptions-item>
            <el-descriptions-item label="支付提供商">{{ currentOrder.payment.provider }}</el-descriptions-item>
            <el-descriptions-item label="支付类型">{{ currentOrder.payment.payment_type }}</el-descriptions-item>
            <el-descriptions-item label="支付人姓名">{{ currentOrder.payment.person_name || '无' }}</el-descriptions-item>
            <el-descriptions-item label="支付人邮箱">{{ currentOrder.payment.person_email || '无' }}</el-descriptions-item>
            <el-descriptions-item label="支付人电话">{{ currentOrder.payment.person_phone || '无' }}</el-descriptions-item>
            <el-descriptions-item label="发票类型">{{ currentOrder.payment.invoice_type || '无' }}</el-descriptions-item>
            <el-descriptions-item label="发票抬头">{{ currentOrder.payment.invoice_title || '无' }}</el-descriptions-item>
            <el-descriptions-item label="发票税号">{{ currentOrder.payment.invoice_tax_id || '无' }}</el-descriptions-item>
            <el-descriptions-item label="发票下载链接">
              <a v-if="currentOrder.payment.invoice_url" :href="currentOrder.payment.invoice_url" target="_blank">下载发票</a>
              <span v-else>无</span>
            </el-descriptions-item>
          </el-descriptions>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="detailDialogVisible = false">关闭</el-button>
          <el-button 
            type="warning" 
            @click="updateOrderStatus(currentOrder)"
            v-if="currentOrder && canUpdateOrderStatus(currentOrder.status)"
          >
            更新状态
          </el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 更新订单状态对话框 -->
    <el-dialog title="更新订单状态" v-model="statusDialogVisible" width="40%">
      <el-form :model="statusForm" label-width="120px">
        <el-form-item label="订单ID">
          <span>{{ statusForm.orderId }}</span>
        </el-form-item>
        <el-form-item label="当前状态">
          <el-tag :type="getOrderStatusType(statusForm.currentStatus)">
            {{ getOrderStatusName(statusForm.currentStatus) }}
          </el-tag>
        </el-form-item>
        <el-form-item label="新状态">
          <el-select v-model="statusForm.newStatus" placeholder="选择新状态">
            <el-option 
              v-for="status in availableStatusOptions" 
              :key="status.value" 
              :label="status.label" 
              :value="status.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="管理员备注">
          <el-input 
            v-model="statusForm.adminNote" 
            type="textarea" 
            :rows="3" 
            placeholder="请输入备注信息"
          ></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="statusDialogVisible = false">取消</el-button>
          <el-button type="primary" @click="submitStatusUpdate">确认更新</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { ref, reactive, onMounted, computed } from 'vue'
import { ElMessage } from 'element-plus'
import { get, put } from '@/utils/request'
import dayjs from 'dayjs' // 引入dayjs用于日期格式化
import { useRouter } from 'vue-router'

// 导入常量
import { 
  ORDER_STATUS, 
  PAYMENT_METHODS, 
} from '@/utils/constants'

export default {
  name: 'OrderManagement',
  setup() {
    const router = useRouter()

    // 状态和数据
    const orders = ref([])
    const total = ref(0)
    const currentPage = ref(1)
    const pageSize = ref(10)
    const loading = ref(false)
    const dateRange = ref([])
    
    // 搜索表单
    const searchForm = reactive({
      userId: '',
      status: '',
      paymentMethod: '',
      startTime: null,
      endTime: null
    })
    
    // 订单状态选项
    const orderStatusOptions = [
      { value: '', label: '全部状态' },
      { value: ORDER_STATUS.CREATED, label: '已创建' },
      { value: ORDER_STATUS.PAID, label: '已支付' },
      { value: ORDER_STATUS.CANCELED, label: '已取消' },
      { value: ORDER_STATUS.EXPIRED, label: '已过期' },
      { value: ORDER_STATUS.TIMEOUT, label: '已超时' },
      { value: ORDER_STATUS.REFUNDED, label: '已退款' },
      { value: ORDER_STATUS.FAILED, label: '支付失败' }
    ]
    
    // 支付方式选项
    const paymentMethodOptions = [
      { value: '', label: '全部方式' },
      { value: PAYMENT_METHODS.ALIPAY, label: '支付宝' },
      { value: PAYMENT_METHODS.WECHAT, label: '微信支付' },
      { value: PAYMENT_METHODS.BANK, label: '银行转账' },
      { value: PAYMENT_METHODS.CREDIT, label: '信用卡' },
      { value: PAYMENT_METHODS.FREE, label: '免费' }
    ]
    
    // 订单详情对话框
    const detailDialogVisible = ref(false)
    const currentOrder = ref(null)
    
    // 状态更新对话框
    const statusDialogVisible = ref(false)
    const statusForm = reactive({
      orderId: '',
      currentStatus: '',
      newStatus: '',
      adminNote: ''
    })
    
    // 可用的状态更新选项
    const availableStatusOptions = computed(() => {
      const currentStatus = statusForm.currentStatus
      
      // 根据当前状态返回可选的新状态
      switch (currentStatus) {
        case ORDER_STATUS.CREATED:
          return [
            { value: ORDER_STATUS.PAID, label: '已支付' },
            { value: ORDER_STATUS.CANCELED, label: '已取消' },
            { value: ORDER_STATUS.FAILED, label: '支付失败' },
            { value: ORDER_STATUS.TIMEOUT, label: '已超时' }
          ]
        case ORDER_STATUS.PAID:
          return [
            { value: ORDER_STATUS.REFUNDED, label: '已退款' },
            { value: ORDER_STATUS.EXPIRED, label: '已过期' }
          ]
        case ORDER_STATUS.FAILED:
          return [
            { value: ORDER_STATUS.PAID, label: '已支付' },
            { value: ORDER_STATUS.CANCELED, label: '已取消' }
          ]
        case ORDER_STATUS.TIMEOUT:
          return [
            { value: ORDER_STATUS.PAID, label: '已支付' },
            { value: ORDER_STATUS.CANCELED, label: '已取消' }
          ]
        default:
          return []
      }
    })
    
    // 获取订单列表
    const fetchOrders = async () => {
      loading.value = true
      try {
        const params = {
          page: currentPage.value,
          page_size: pageSize.value
        }
        
        if (searchForm.status) {
          params.status = searchForm.status
        }
        
        if (searchForm.userId) {
          params.user_id = searchForm.userId
        }
        
        if (searchForm.paymentMethod) {
          params.payment_method = searchForm.paymentMethod
        }
        
        if (searchForm.startTime) {
          params.start_time = searchForm.startTime
        }
        
        if (searchForm.endTime) {
          params.end_time = searchForm.endTime
        }
        
        const res = await get('/auth/trade/orders', params)
        
        if (res?.data) {
          orders.value = res.data.orders || []
          total.value = res.data.total || 0
        } else {
          orders.value = []
          total.value = 0
          ElMessage.warning('获取订单数据失败')
        }
      } catch (error) {
        console.error('获取订单列表失败:', error)
        ElMessage.error('获取订单列表失败')
      } finally {
        loading.value = false
      }
    }
    
    // 搜索订单
    const searchOrders = () => {
      currentPage.value = 1
      fetchOrders()
    }
    
    // 重置搜索
    const resetSearch = () => {
      Object.assign(searchForm, {
        userId: '',
        status: '',
        paymentMethod: '',
        startTime: null,
        endTime: null
      })
      dateRange.value = []
      searchOrders()
    }
    
    // 处理日期范围变化
    const handleDateRangeChange = (val) => {
      if (val && val.length === 2) {
        searchForm.startTime = val[0]
        searchForm.endTime = val[1]
      } else {
        searchForm.startTime = null
        searchForm.endTime = null
      }
    }
    
    // 查看订单详情
    const viewOrderDetail = (order) => {
      currentOrder.value = order
      detailDialogVisible.value = true
    }
    
    // 更新订单状态
    const updateOrderStatus = (order) => {
      if (!order) return
      
      statusForm.orderId = order.id
      statusForm.currentStatus = order.status
      statusForm.newStatus = ''
      statusForm.adminNote = order.admin_note || ''
      
      statusDialogVisible.value = true
    }
    
    // 提交状态更新
    const submitStatusUpdate = async () => {
      if (!statusForm.newStatus) {
        ElMessage.warning('请选择新状态')
        return
      }
      
      try {
        loading.value = true
        const res = await put(`/auth/admin/trade/order/${statusForm.orderId}/status`, {
          status: statusForm.newStatus,
          admin_note: statusForm.adminNote
        })
        
        if (res?.data) {
          ElMessage.success('订单状态更新成功')
          statusDialogVisible.value = false
          
          // 更新当前订单和列表中的订单状态
          if (currentOrder.value && currentOrder.value.id === statusForm.orderId) {
            currentOrder.value = res.data
          }
          
          fetchOrders()
        } else {
          ElMessage.error('订单状态更新失败')
        }
      } catch (error) {
        console.error('更新订单状态失败:', error)
        ElMessage.error('更新订单状态失败')
      } finally {
        loading.value = false
      }
    }
    
    // 判断是否可以更新订单状态
    const canUpdateOrderStatus = (status) => {
      return [ORDER_STATUS.CREATED, ORDER_STATUS.PAID, ORDER_STATUS.FAILED, ORDER_STATUS.TIMEOUT].includes(status)
    }
    
    // 获取订单状态名称
    const getOrderStatusName = (status) => {
      const statusMap = {
        [ORDER_STATUS.CREATED]: '已创建',
        [ORDER_STATUS.PAID]: '已支付',
        [ORDER_STATUS.CANCELED]: '已取消',
        [ORDER_STATUS.EXPIRED]: '已过期',
        [ORDER_STATUS.TIMEOUT]: '已超时',
        [ORDER_STATUS.REFUNDED]: '已退款',
        [ORDER_STATUS.FAILED]: '支付失败'
      }
      return statusMap[status] || status
    }
    
    // 获取订单状态类型（用于标签颜色）
    const getOrderStatusType = (status) => {
      const typeMap = {
        [ORDER_STATUS.CREATED]: 'info',
        [ORDER_STATUS.PAID]: 'success',
        [ORDER_STATUS.CANCELED]: 'danger',
        [ORDER_STATUS.EXPIRED]: 'warning',
        [ORDER_STATUS.TIMEOUT]: 'warning',
        [ORDER_STATUS.REFUNDED]: 'warning',
        [ORDER_STATUS.FAILED]: 'danger'
      }
      return typeMap[status] || 'info'
    }
    
    // 获取支付方式名称
    const getPaymentMethodName = (method) => {
      if (!method) return '未选择支付方式'
      
      const methodMap = {
        [PAYMENT_METHODS.ALIPAY]: '支付宝',
        [PAYMENT_METHODS.WECHAT]: '微信支付',
        [PAYMENT_METHODS.BANK]: '银行转账',
        [PAYMENT_METHODS.CREDIT]: '信用卡',
        [PAYMENT_METHODS.FREE]: '免费'
      }
      return methodMap[method] || method
    }
    
    // 分页处理
    const handleSizeChange = (size) => {
      pageSize.value = size
      fetchOrders()
    }
    
    const handleCurrentChange = (page) => {
      currentPage.value = page
      fetchOrders()
    }
    
    // 格式化日期函数
    const formatDate = (timestamp) => {
      if (!timestamp) return '';
      return dayjs(timestamp * 1000).format('YYYY-MM-DD HH:mm:ss');
    }
    
    // 初始化
    onMounted(() => {
      fetchOrders()
    })

    const viewSubscription = (subscriptionId) => {
      router.push({
        path: '/admin/subscriptions',
        query: { subscriptionId }
      })
    }
    
    return {
      orders,
      total,
      currentPage,
      pageSize,
      loading,
      searchForm,
      dateRange,
      orderStatusOptions,
      paymentMethodOptions,
      detailDialogVisible,
      currentOrder,
      statusDialogVisible,
      statusForm,
      availableStatusOptions,
      searchOrders,
      resetSearch,
      handleDateRangeChange,
      viewOrderDetail,
      updateOrderStatus,
      submitStatusUpdate,
      canUpdateOrderStatus,
      getOrderStatusName,
      getOrderStatusType,
      getPaymentMethodName,
      handleSizeChange,
      handleCurrentChange,
      formatDate,
      viewSubscription
    }
  }
}
</script>

<style scoped>
.order-management {
  height: 100%;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mt-4 {
  margin-top: 1rem;
}

.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.search-area {
  flex-grow: 1;
}

.search-form {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.button-area {
  display: flex;
  gap: 0.5rem;
  margin-top: 1rem;
}

.pagination-container {
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;
}

.text-muted {
  color: #909399;
  font-size: 0.85em;
}

.order-detail h3 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: bold;
  color: #409EFF;
}

.subscription-info, .payment-info {
  margin-top: 20px;
}
</style> 