<template>
  <div class="distribution-profits">
    <el-card class="filter-card">
      <div class="filter-container">
        <el-form :inline="true" :model="searchForm" class="search-form">
          <el-form-item label="获取分润用户ID">
            <el-input v-model="searchForm.dis_get_user_id" placeholder="请输入获取分润用户ID" clearable></el-input>
          </el-form-item>
          <el-form-item label="设置分润用户ID">
            <el-input v-model="searchForm.dis_set_user_id" placeholder="请输入设置分润用户ID" clearable></el-input>
          </el-form-item>
          <el-form-item label="关联订单ID">
            <el-input v-model="searchForm.dis_order_id" placeholder="请输入关联订单ID" clearable></el-input>
          </el-form-item>
          <el-form-item label="账户类型">
            <el-select v-model="searchForm.account_type" placeholder="请选择账户类型" clearable>
              <el-option label="交易分润" value="trade"></el-option>
              <el-option label="邀请分润" value="invite"></el-option>
              <el-option label="升级分润" value="level"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="身份类型">
            <el-input v-model="searchForm.identity_type" placeholder="请输入身份类型" clearable></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handleSearch">查询</el-button>
            <el-button @click="resetSearch">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-card>

    <el-card class="table-card">
      <template #header>
        <div class="card-header">
          <span>分润信息列表</span>
        </div>
      </template>

      <el-table
        v-loading="loading"
        :data="profitsList"
        border
        style="width: 100%"
      >
        <el-table-column prop="id" label="ID" width="80" align="center"></el-table-column>
        <el-table-column prop="dis_get_user_id" label="获取分润用户" min-width="150" align="center"></el-table-column>
        <el-table-column prop="dis_set_user_id" label="设置分润用户" min-width="150" align="center"></el-table-column>
        <el-table-column prop="dis_amount" label="分润金额" width="120" align="center">
          <template #default="scope">
            <span :class="getAmountClass(scope.row.dis_amount)">¥{{ formatAmount(scope.row.dis_amount) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="dis_order_id" label="关联订单" min-width="150" align="center"></el-table-column>
        <el-table-column prop="identity_type" label="身份类型" width="120" align="center"></el-table-column>
        <el-table-column prop="account_type" label="账户类型" width="120" align="center">
          <template #default="scope">
            <el-tag :type="getAccountTypeTag(scope.row.account_type)">
              {{ getAccountTypeName(scope.row.account_type) }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="dis_user_type" label="用户类型" width="120" align="center"></el-table-column>
        <el-table-column prop="type_detail" label="类型详情" min-width="150" align="center"></el-table-column>
        <el-table-column prop="add_time" label="添加时间" min-width="180" align="center"></el-table-column>
      </el-table>

      <div class="pagination-container">
        <el-pagination
          v-model:current-page="currentPage"
          v-model:page-size="pageSize"
          :page-sizes="[10, 20, 50, 100]"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
import { ref, reactive, onMounted } from 'vue'
import { ElMessage } from 'element-plus'
import { get } from '@/utils/request'
import { getAmountClass, formatAmount } from '@/utils/amount'
import '@/styles/amount.css'

export default {
  name: 'DistributionProfits',
  setup() {
    const loading = ref(false)
    const profitsList = ref([])
    const currentPage = ref(1)
    const pageSize = ref(10)
    const total = ref(0)

    const searchForm = reactive({
      dis_get_user_id: '',
      dis_set_user_id: '',
      dis_order_id: '',
      account_type: '',
      identity_type: ''
    })

    const fetchProfits = async () => {
      loading.value = true
      try {
        const params = {
          offset: (currentPage.value - 1) * pageSize.value,
          limit: pageSize.value,
          ...searchForm
        }
        
        Object.keys(params).forEach(key => {
          if (params[key] === '') {
            delete params[key]
          }
        })
        
        const response = await get('/auth/distributor/profits', params)
        if (response.code === 200) {
          profitsList.value = response.data.rows || []
          total.value = response.data.total || 0
        } else {
          ElMessage.error(response.msg || '获取分润信息列表失败')
        }
      } catch (error) {
        console.error('获取分润信息列表失败:', error)
        ElMessage.error('获取分润信息列表失败')
      } finally {
        loading.value = false
      }
    }

    const handleSearch = () => {
      currentPage.value = 1
      fetchProfits()
    }

    const resetSearch = () => {
      Object.keys(searchForm).forEach(key => {
        searchForm[key] = ''
      })
      currentPage.value = 1
      fetchProfits()
    }

    const handleSizeChange = (size) => {
      pageSize.value = size
      fetchProfits()
    }

    const handleCurrentChange = (page) => {
      currentPage.value = page
      fetchProfits()
    }

    const getAccountTypeName = (type) => {
      const typeMap = {
        'trade': '交易分润',
        'invite': '邀请分润',
        'level': '升级分润'
      }
      return typeMap[type] || type
    }

    const getAccountTypeTag = (type) => {
      const typeMap = {
        'trade': 'success',
        'invite': 'warning',
        'level': 'info'
      }
      return typeMap[type] || 'default'
    }

    onMounted(() => {
      fetchProfits()
    })

    return {
      loading,
      profitsList,
      currentPage,
      pageSize,
      total,
      searchForm,
      handleSearch,
      resetSearch,
      handleSizeChange,
      handleCurrentChange,
      getAccountTypeName,
      getAccountTypeTag,
      formatAmount,
      getAmountClass
    }
  }
}
</script>

<style scoped>
.distribution-profits {
  padding: 20px;
}

.filter-card {
  margin-bottom: 20px;
}

.filter-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.search-form {
  width: 100%;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pagination-container {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}
</style> 