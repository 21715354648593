<template>
  <div class="distribution-management">
    <el-card class="filter-card">
      <div class="filter-container">
        <el-form :inline="true" :model="searchForm" class="search-form">
          <el-form-item label="分销员ID">
            <el-input v-model="searchForm.dis_user_id" placeholder="请输入分销员ID" clearable></el-input>
          </el-form-item>
          <el-form-item label="上级标识">
            <el-input v-model="searchForm.dis_model_id" placeholder="请输入上级标识" clearable></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handleSearch">查询</el-button>
            <el-button @click="resetSearch">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-card>

    <el-card class="table-card">
      <template #header>
        <div class="card-header">
          <span>分销会员列表</span>
        </div>
      </template>

      <el-table
        v-loading="loading"
        :data="distributorList"
        border
        style="width: 100%"
        :header-cell-style="{ background: '#f5f7fa', color: '#606266' }"
      >
        <el-table-column prop="id" label="ID" width="80"></el-table-column>
        <el-table-column prop="dis_user_id" label="分销员ID" width="180" show-overflow-tooltip></el-table-column>
        <el-table-column prop="dis_user_name" label="用户名" width="150" show-overflow-tooltip></el-table-column>
        <el-table-column prop="dis_user_rank" label="用户段位" width="120"></el-table-column>
        <el-table-column prop="rank_integral" label="等级积分" width="120"></el-table-column>
        <el-table-column prop="dis_model_id" label="上级ID" width="180" show-overflow-tooltip></el-table-column>
        <el-table-column prop="dis_full_index" label="完整索引" min-width="200" show-overflow-tooltip></el-table-column>
        <el-table-column label="限制状态" width="100">
          <template #default="scope">
            <el-tag :type="getConfineStatusType(scope.row.confine_status)">
              {{ getConfineStatusName(scope.row.confine_status) }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column label="添加时间" width="180">
          <template #default="scope">
            <span>{{ scope.row.add_time }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="180" fixed="right">
          <template #default="scope">
            <el-button type="primary" size="small" @click="viewDistributorDetail(scope.row)">查看</el-button>
            <el-button 
              type="warning" 
              size="small" 
              @click="toggleConfineStatus(scope.row)"
              :disabled="loading"
            >
              {{ scope.row.confine_status === 1 ? '解除限制' : '限制' }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <div class="pagination-container">
        <el-pagination
          v-model:current-page="currentPage"
          v-model:page-size="pageSize"
          :page-sizes="[10, 20, 50, 100]"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </el-card>

    <!-- 分销员详情对话框 -->
    <el-dialog
      v-model="dialogVisible"
      title="分销员详情"
      width="600px"
    >
      <div v-if="currentDistributor" class="distributor-detail">
        <el-descriptions :column="2" border>
          <el-descriptions-item label="ID">{{ currentDistributor.id }}</el-descriptions-item>
          <el-descriptions-item label="分销员ID">{{ currentDistributor.dis_user_id }}</el-descriptions-item>
          <el-descriptions-item label="用户名">{{ currentDistributor.dis_user_name }}</el-descriptions-item>
          <el-descriptions-item label="用户类型">{{ currentDistributor.dis_user_type }}</el-descriptions-item>
          <el-descriptions-item label="用户等级">{{ currentDistributor.dis_user_rank }}</el-descriptions-item>
          <el-descriptions-item label="等级积分">{{ currentDistributor.rank_integral }}</el-descriptions-item>
          <el-descriptions-item label="上级标识">{{ currentDistributor.dis_model_id }}</el-descriptions-item>
          <el-descriptions-item label="平台ID">{{ currentDistributor.dis_platform_id }}</el-descriptions-item>
          <el-descriptions-item label="限制状态">
            <el-tag :type="getConfineStatusType(currentDistributor.confine_status)">
              {{ getConfineStatusName(currentDistributor.confine_status) }}
            </el-tag>
          </el-descriptions-item>
          <el-descriptions-item label="添加时间">{{ currentDistributor.add_time }}</el-descriptions-item>
          <el-descriptions-item label="更新时间">{{ currentDistributor.update_time }}</el-descriptions-item>
          <el-descriptions-item label="完整索引" :span="2">{{ currentDistributor.dis_full_index }}</el-descriptions-item>
        </el-descriptions>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false">关闭</el-button>
          <el-button 
            type="warning" 
            @click="toggleConfineStatus(currentDistributor)"
            :disabled="loading"
          >
            {{ currentDistributor?.confine_status === 1 ? '解除限制' : '限制' }}
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { ref, reactive, onMounted } from 'vue'
import { ElMessage } from 'element-plus'
import { get, put } from '@/utils/request'

export default {
  name: 'DistributionMembers',
  setup() {
    // 状态变量
    const loading = ref(false)
    const distributorList = ref([])
    const currentPage = ref(1)
    const pageSize = ref(10)
    const total = ref(0)
    const dialogVisible = ref(false)
    const currentDistributor = ref(null)

    // 搜索表单
    const searchForm = reactive({
      dis_user_id: '',
      dis_model_id: ''
    })

    // 初始化
    onMounted(() => {
      fetchDistributors()
    })

    // 获取分销员列表
    const fetchDistributors = async () => {
      loading.value = true
      try {
        const params = {
          offset: (currentPage.value - 1) * pageSize.value,
          limit: pageSize.value,
          ...searchForm
        }
        
        // 移除空值
        Object.keys(params).forEach(key => {
          if (params[key] === '') {
            delete params[key]
          }
        })
        
        const response = await get('/auth/admin/distributor/list', params)
        if (response.code === 200) {
          // 确保数据格式化
          distributorList.value = (response.data.rows || []).map(item => ({
            ...item,
            // 格式化时间显示
            add_time: formatDateTime(item.add_time),
            update_time: formatDateTime(item.update_time),
            // 确保数值类型
            rank_integral: Number(item.rank_integral),
            confine_status: Number(item.confine_status)
          }))
          total.value = Number(response.data.total) || 0
        } else {
          ElMessage.error(response.msg || '获取分销员列表失败')
        }
      } catch (error) {
        console.error('获取分销员列表失败:', error)
        ElMessage.error('获取分销员列表失败')
      } finally {
        loading.value = false
      }
    }

    // 添加日期格式化函数
    const formatDateTime = (dateStr) => {
      if (!dateStr) return ''
      const date = new Date(dateStr)
      if (isNaN(date.getTime())) return dateStr
      return date.toLocaleString('zh-CN', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
      }).replace(/\//g, '-')
    }

    // 查询
    const handleSearch = () => {
      currentPage.value = 1
      fetchDistributors()
    }

    // 重置搜索
    const resetSearch = () => {
      Object.keys(searchForm).forEach(key => {
        searchForm[key] = ''
      })
      currentPage.value = 1
      fetchDistributors()
    }

    // 分页处理
    const handleSizeChange = (size) => {
      pageSize.value = size
      fetchDistributors()
    }

    const handleCurrentChange = (page) => {
      currentPage.value = page
      fetchDistributors()
    }

    // 查看分销员详情
    const viewDistributorDetail = (distributor) => {
      currentDistributor.value = distributor
      dialogVisible.value = true
    }

    // 切换限制状态
    const toggleConfineStatus = async (distributor) => {
      if (!distributor) return
      
      loading.value = true
      try {
        const newStatus = distributor.confine_status === 1 ? 0 : 1
        const response = await put(`/auth/admin/distributor/${distributor.id}/status`, {
          confine_status: newStatus
        })
        
        if (response.code === 200) {
          ElMessage.success(`${newStatus === 1 ? '限制' : '解除限制'}成功`)
          
          // 更新本地数据
          if (currentDistributor.value && currentDistributor.value.id === distributor.id) {
            currentDistributor.value.confine_status = newStatus
          }
          
          const index = distributorList.value.findIndex(item => item.id === distributor.id)
          if (index !== -1) {
            distributorList.value[index].confine_status = newStatus
          }
          
          dialogVisible.value = false
        } else {
          ElMessage.error(response.msg || '操作失败')
        }
      } catch (error) {
        console.error('操作失败:', error)
        ElMessage.error('操作失败')
      } finally {
        loading.value = false
      }
    }

    // 获取限制状态名称
    const getConfineStatusName = (status) => {
      return status === 1 ? '已限制' : '正常'
    }

    // 获取限制状态类型（用于标签颜色）
    const getConfineStatusType = (status) => {
      return status === 1 ? 'danger' : 'success'
    }

    return {
      loading,
      distributorList,
      currentPage,
      pageSize,
      total,
      searchForm,
      dialogVisible,
      currentDistributor,
      handleSearch,
      resetSearch,
      handleSizeChange,
      handleCurrentChange,
      viewDistributorDetail,
      toggleConfineStatus,
      getConfineStatusName,
      getConfineStatusType
    }
  }
}
</script>

<style scoped>
.distribution-management {
  padding: 20px;
}

.filter-card {
  margin-bottom: 20px;
}

.filter-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.search-form {
  width: 100%;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pagination-container {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}

.distributor-detail {
  margin-bottom: 20px;
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}
</style> 