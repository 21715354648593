import { get } from './request'

const CACHE_KEY = 'APP_CONSTANTS'
const CACHE_EXPIRY = 24 * 60 * 60 * 1000 // 24小时

// 用户身份常量定义
export const USER_IDENTITIES = {
  IdentityAllAdmin: 'admin', 
  IdentityAllUser: 'public', 
  IdentitySuper: 'super',    
  IdentityAdmin: 'admin',    
  IdentityTeacher: 'teacher',
  IdentityNormal: 'public'   
}

// 用户身份列表
export const USER_IDENTITY_LIST = [
  {
    Key: "超级管理员",
    Value: USER_IDENTITIES.IdentitySuper
  },
  {
    Key: "管理员", 
    Value: USER_IDENTITIES.IdentityAdmin
  },
  {
    Key: "老师",
    Value: USER_IDENTITIES.IdentityTeacher
  },
  {
    Key: "普通用户",
    Value: USER_IDENTITIES.IdentityNormal
  }
]

// 节点类型定义
export const NODE_TYPES = {
  ROOT: 0,      // 根节点
  PROJECT: 1,   // 项目
  MODULE: 2,    // 模块
  TEACHER: 3,   // 老师
  AGE: 4,       // 年龄段
  MAJOR: 5,     // 专业
  PAPER: 6      // 试卷
}

// 节点类型列表
export const NODE_TYPE_LIST = [
  {
    key: "根节点",
    value: NODE_TYPES.ROOT
  },
  {
    key: "项目",
    value: NODE_TYPES.PROJECT
  },
  {
    key: "模块",
    value: NODE_TYPES.MODULE
  },
  {
    key: "老师",
    value: NODE_TYPES.TEACHER
  },
  {
    key: "年龄段",
    value: NODE_TYPES.AGE
  },
  {
    key: "专业",
    value: NODE_TYPES.MAJOR
  },
  {
    key: "试卷",
    value: NODE_TYPES.PAPER
  }
]

// 文件业务类型定义
export const FILE_BUSINESS_TYPES = {
  UNKNOWN: 0,
  USER_AVATAR: 1,
  USER_COVER: 2,
  BABY_AVATAR: 3,
  QUESTION_IMAGE: 4,
  QUESTION_VIDEO: 5,
  QUOTA_DIARY_IMAGE: 6,
  QUOTA_DIARY_VIDEO: 7,
  QUESTION_AUDIO: 8,
  QUESTION_FILE: 9
}

// 文件类型列表
export const FILE_TYPE_LIST = [
  {
    key: "用户头像",
    value: FILE_BUSINESS_TYPES.USER_AVATAR
  },
  {
    key: "用户封面",
    value: FILE_BUSINESS_TYPES.USER_COVER
  },
  {
    key: "宝宝头像",
    value: FILE_BUSINESS_TYPES.BABY_AVATAR
  },
  {
    key: "题目示意图",
    value: FILE_BUSINESS_TYPES.QUESTION_IMAGE
  },
  {
    key: "题目示意视频",
    value: FILE_BUSINESS_TYPES.QUESTION_VIDEO
  },
  {
    key: "日记图片",
    value: FILE_BUSINESS_TYPES.QUOTA_DIARY_IMAGE
  },
  {
    key: "日记视频",
    value: FILE_BUSINESS_TYPES.QUOTA_DIARY_VIDEO
  },
  {
    key: "题目示意音频",
    value: FILE_BUSINESS_TYPES.QUESTION_AUDIO
  },
  {
    key: "题目示意文件",
    value: FILE_BUSINESS_TYPES.QUESTION_FILE
  }
]

// 添加导出文件类型定义
export const EXPORT_FILE_TYPES = [
  {
    key: "Excel文件",
    value: "excel"
  },
  {
    key: "CSV文件", 
    value: "csv"
  },
  {
    key: "PDF文件",
    value: "pdf"
  },
  {
    key: "Word文件",
    value: "word"
  }
]

// 订单状态常量
export const ORDER_STATUS = {
  CREATED: 'Created',
  PAID: 'Paid',
  CANCELED: 'Canceled',
  EXPIRED: 'Expired',
  TIMEOUT: 'Timeout',
  REFUNDED: 'Refunded',
  FAILED: 'Failed'
}

// 支付方式常量
export const PAYMENT_METHODS = {
  ALIPAY: 'Alipay',
  WECHAT: 'WeChat',
  BANK: 'bank',
  CREDIT: 'credit',
  FREE: 'free'
}

// 支付提供商常量
export const PAYMENT_PROVIDERS = {
  ALIPAY: 'alipay',
  WECHAT: 'wechat',
  ALIPAY_SANDBOX: 'alipay_sandbox',
  WECHAT_SANDBOX: 'wechat_sandbox'
}

// 客户端类型常量
export const CLIENT_TYPES = {
  APP: 'app-plus',
  MP_WECHAT: 'mp-weixin',
  WEB: 'WEB'
}

// 会员计划常量
export const MEMBERSHIP_PLANS = {
  MONTHLY: 'monthly-membership-plan',
  YEARLY: 'yearly-membership-plan'
}

// 会员定价常量
export const MEMBERSHIP_PRICING = {
  MONTHLY: 'monthly-membership-pricing',
  YEARLY: 'yearly-membership-pricing'
}

// 订阅状态常量
export const SUBSCRIPTION_STATUS = {
  ACTIVE: 'Active',
  CANCELED: 'Canceled',
  EXPIRED: 'Expired',
  PENDING: 'Pending',
  SUSPENDED: 'Suspended'
}

const constantsMap = {
}

export async function fetchAndCacheConstants() {
  const cachedData = localStorage.getItem(CACHE_KEY)
  let constants = {}
  let needsFetch = false

  if (cachedData) {
    const { timestamp, data } = JSON.parse(cachedData)
    if (Date.now() - timestamp < CACHE_EXPIRY) {
      constants = data
      // 检查是否所有常量都被缓存
      needsFetch = Object.keys(constantsMap).some(key => !constants[key] || constants[key].length === 0)
    } else {
      needsFetch = true
    }
  } else {
    needsFetch = true
  }

  if (needsFetch) {
    for (const [key, url] of Object.entries(constantsMap)) {
      if (!constants[key] || constants[key].length === 0) {
        try {
          const response = await get(url)
          constants[key] = response.data
        } catch (error) {
          console.error(`获取 ${key} 失败:`, error)
        }
      }
    }

    // 添加本地常量列表
    constants.fileTypes = FILE_TYPE_LIST
    constants.classifyTypes = NODE_TYPE_LIST

    localStorage.setItem(CACHE_KEY, JSON.stringify({
      timestamp: Date.now(),
      data: constants
    }))
  }

  return constants
}

export function getConstants() {
  const cachedData = localStorage.getItem(CACHE_KEY)
  if (cachedData) {
    const { data } = JSON.parse(cachedData)
    return data
  }
  return null
}

export function getConstantByKey(key) {
  const constants = getConstants()
  return constants ? constants[key] : null
}

export function getIdentities() {
  return USER_IDENTITY_LIST
}

export function hasIdentity(requiredIdentity) {
  const userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}')
  const userIdentity = userInfo.identity
  const identities = getIdentities()

  const requiredIdentityObj = identities.find(i => i.Key === requiredIdentity)
  if (!requiredIdentityObj) return false

  if (requiredIdentityObj.Value === USER_IDENTITIES.IdentityAllUser) return true
  if (requiredIdentity === 'IdentityAllAdmin') {
    const adminIdentities = identities.filter(i => 
      i.Value === USER_IDENTITIES.IdentityAdmin || 
      i.Value === USER_IDENTITIES.IdentitySuper
    )
    return adminIdentities.some(i => i.Value === userIdentity)
  }
  return userIdentity === requiredIdentityObj.Value
}

export function isSuperAdmin() {
  const userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}')
  const userIdentity = userInfo.user.identity
  return userIdentity === USER_IDENTITIES.IdentitySuper
}

// 在 getFileTypeValue 函数中添加错误处理和日志
export function getFileTypeValue(typeName) {
  const fileTypes = FILE_TYPE_LIST
  if (!fileTypes || fileTypes.length === 0) {
    console.error('fileTypes 为空或未加载:', fileTypes)
    return null
  }
  
  const type = fileTypes.find(t => t.key === typeName)
  if (!type) {
    console.error(`未找到文件类型 "${typeName}" 在:`, fileTypes)
    return null
  }
  
  return type.value
}
