<template>
    <div class="distribution-withdrawals">
      <el-card class="filter-card">
        <div class="filter-container">
          <el-form :inline="true" :model="searchForm" class="search-form">
            <el-form-item label="分销员ID">
              <el-input v-model="searchForm.dis_user_id" placeholder="请输入分销员ID" clearable></el-input>
            </el-form-item>
            <el-form-item label="提现单号">
              <el-input v-model="searchForm.withdraw_num" placeholder="请输入提现单号" clearable></el-input>
            </el-form-item>
            <el-form-item label="提现状态">
              <el-select v-model="searchForm.withdraw_status" placeholder="请选择提现状态" clearable>
                <el-option label="待审核" value="pending"></el-option>
                <el-option label="已通过" value="approved"></el-option>
                <el-option label="已拒绝" value="rejected"></el-option>
                <el-option label="已完成" value="completed"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="账户类型">
              <el-select v-model="searchForm.account_type" placeholder="请选择账户类型" clearable>
                <el-option label="交易分润" value="trade"></el-option>
                <el-option label="邀请分润" value="invite"></el-option>
                <el-option label="升级分润" value="level"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button @click="resetSearch">重置</el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-card>
  
      <el-card class="table-card">
        <template #header>
          <div class="card-header">
            <span>提现申请列表</span>
          </div>
        </template>
  
        <el-table
          v-loading="loading"
          :data="withdrawalsList"
          border
          style="width: 100%"
        >
          <el-table-column prop="id" label="ID" width="80"></el-table-column>
          <el-table-column prop="withdraw_num" label="提现单号" width="180"></el-table-column>
          <el-table-column prop="dis_user_id" label="分销员ID" width="150"></el-table-column>
          <el-table-column label="提现金额" width="200">
            <template #default="scope">
              <div>总金额: <span :class="getAmountClass(scope.row.total_amount)">¥{{ formatAmount(scope.row.total_amount) }}</span></div>
              <div>实际到账: <span :class="getAmountClass(scope.row.real_amount)">¥{{ formatAmount(scope.row.real_amount) }}</span></div>
              <div>手续费: <span :class="getAmountClass(scope.row.fee_amount)">¥{{ formatAmount(scope.row.fee_amount) }}</span></div>
            </template>
          </el-table-column>
          <el-table-column prop="withdraw_status" label="提现状态" width="120">
            <template #default="scope">
              <el-tag :type="getStatusType(scope.row.withdraw_status)">
                {{ getStatusName(scope.row.withdraw_status) }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="account_type" label="账户类型" width="120">
            <template #default="scope">
              <el-tag :type="getAccountTypeTag(scope.row.account_type)">
                {{ getAccountTypeName(scope.row.account_type) }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="withdraw_desc" label="提现描述" width="200"></el-table-column>
          <el-table-column prop="withdraw_time" label="提现时间" width="180"></el-table-column>
          <el-table-column prop="handle_time" label="处理时间" width="180"></el-table-column>
          <el-table-column label="操作" width="200" fixed="right">
            <template #default="scope">
              <el-button 
                v-if="scope.row.withdraw_status === 'pending'"
                type="success" 
                size="small" 
                @click="handleWithdrawal(scope.row, 'approved')"
              >
                通过
              </el-button>
              <el-button 
                v-if="scope.row.withdraw_status === 'pending'"
                type="danger" 
                size="small" 
                @click="handleWithdrawal(scope.row, 'rejected')"
              >
                拒绝
              </el-button>
              <el-button 
                type="primary" 
                size="small" 
                @click="viewWithdrawalDetail(scope.row)"
              >
                查看
              </el-button>
            </template>
          </el-table-column>
        </el-table>
  
        <div class="pagination-container">
          <el-pagination
            v-model:current-page="currentPage"
            v-model:page-size="pageSize"
            :page-sizes="[10, 20, 50, 100]"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
      </el-card>
  
      <!-- 提现详情对话框 -->
      <el-dialog
        v-model="dialogVisible"
        title="提现详情"
        width="600px"
      >
        <div v-if="currentWithdrawal" class="withdrawal-detail">
          <el-descriptions :column="2" border>
            <el-descriptions-item label="提现单号">{{ currentWithdrawal.withdraw_num }}</el-descriptions-item>
            <el-descriptions-item label="分销员ID">{{ currentWithdrawal.dis_user_id }}</el-descriptions-item>
            <el-descriptions-item label="提现总金额">¥{{ currentWithdrawal.total_amount }}</el-descriptions-item>
            <el-descriptions-item label="实际到账">¥{{ currentWithdrawal.real_amount }}</el-descriptions-item>
            <el-descriptions-item label="手续费">¥{{ currentWithdrawal.fee_amount }}</el-descriptions-item>
            <el-descriptions-item label="账户类型">
              <el-tag :type="getAccountTypeTag(currentWithdrawal.account_type)">
                {{ getAccountTypeName(currentWithdrawal.account_type) }}
              </el-tag>
            </el-descriptions-item>
            <el-descriptions-item label="提现状态">
              <el-tag :type="getStatusType(currentWithdrawal.withdraw_status)">
                {{ getStatusName(currentWithdrawal.withdraw_status) }}
              </el-tag>
            </el-descriptions-item>
            <el-descriptions-item label="提现时间">{{ currentWithdrawal.withdraw_time }}</el-descriptions-item>
            <el-descriptions-item label="处理时间">{{ currentWithdrawal.handle_time }}</el-descriptions-item>
            <el-descriptions-item label="提现描述" :span="2">{{ currentWithdrawal.withdraw_desc }}</el-descriptions-item>
          </el-descriptions>
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="dialogVisible = false">关闭</el-button>
            <template v-if="currentWithdrawal?.withdraw_status === 'pending'">
              <el-button type="success" @click="handleWithdrawal(currentWithdrawal, 'approved')">通过</el-button>
              <el-button type="danger" @click="handleWithdrawal(currentWithdrawal, 'rejected')">拒绝</el-button>
            </template>
          </span>
        </template>
      </el-dialog>
    </div>
  </template>
  
  <script>
  import { ref, reactive, onMounted } from 'vue'
  import { ElMessage, ElMessageBox } from 'element-plus'
  import { get, put } from '@/utils/request'
  import { getAmountClass, formatAmount } from '@/utils/amount'
  import '@/styles/amount.css'
  
  export default {
    name: 'DistributionWithdrawals',
    setup() {
      const loading = ref(false)
      const withdrawalsList = ref([])
      const currentPage = ref(1)
      const pageSize = ref(10)
      const total = ref(0)
      const dialogVisible = ref(false)
      const currentWithdrawal = ref(null)
  
      const searchForm = reactive({
        dis_user_id: '',
        withdraw_num: '',
        withdraw_status: '',
        account_type: ''
      })
  
      const fetchWithdrawals = async () => {
        loading.value = true
        try {
          const params = {
            offset: (currentPage.value - 1) * pageSize.value,
            limit: pageSize.value,
            ...searchForm
          }
          
          Object.keys(params).forEach(key => {
            if (params[key] === '') {
              delete params[key]
            }
          })
          
          const response = await get('/auth/admin/distributor/withdrawals', params)
          if (response.code === 200) {
            withdrawalsList.value = response.data.rows || []
            total.value = response.data.total || 0
          } else {
            ElMessage.error(response.msg || '获取提现申请列表失败')
          }
        } catch (error) {
          console.error('获取提现申请列表失败:', error)
          ElMessage.error('获取提现申请列表失败')
        } finally {
          loading.value = false
        }
      }
  
      const handleSearch = () => {
        currentPage.value = 1
        fetchWithdrawals()
      }
  
      const resetSearch = () => {
        Object.keys(searchForm).forEach(key => {
          searchForm[key] = ''
        })
        currentPage.value = 1
        fetchWithdrawals()
      }
  
      const handleSizeChange = (size) => {
        pageSize.value = size
        fetchWithdrawals()
      }
  
      const handleCurrentChange = (page) => {
        currentPage.value = page
        fetchWithdrawals()
      }
  
      const viewWithdrawalDetail = (withdrawal) => {
        currentWithdrawal.value = withdrawal
        dialogVisible.value = true
      }
  
      const handleWithdrawal = async (withdrawal, action) => {
        try {
          const confirmText = action === 'approved' ? '确认通过该提现申请吗？' : '确认拒绝该提现申请吗？'
          await ElMessageBox.confirm(confirmText, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: action === 'approved' ? 'success' : 'warning'
          })
  
          const response = await put(`/auth/admin/distributor/withdrawal/${withdrawal.id}/status`, {
            status: action
          })
  
          if (response.code === 200) {
            ElMessage.success(`${action === 'approved' ? '通过' : '拒绝'}提现申请成功`)
            dialogVisible.value = false
            fetchWithdrawals()
          } else {
            ElMessage.error(response.msg || '操作失败')
          }
        } catch (error) {
          console.error('操作失败:', error)
          ElMessage.error('操作失败')
        }
      }
  
      const getStatusName = (status) => {
        const statusMap = {
          'pending': '待审核',
          'approved': '已通过',
          'rejected': '已拒绝',
          'completed': '已完成'
        }
        return statusMap[status] || status
      }
  
      const getStatusType = (status) => {
        const statusMap = {
          'pending': 'warning',
          'approved': 'success',
          'rejected': 'danger',
          'completed': 'info'
        }
        return statusMap[status] || ''
      }
  
      const getAccountTypeName = (type) => {
        const typeMap = {
          'trade': '交易分润',
          'invite': '邀请分润',
          'level': '升级分润'
        }
        return typeMap[type] || type
      }
  
      const getAccountTypeTag = (type) => {
        const typeMap = {
          'trade': 'success',
          'invite': 'warning',
          'level': 'info'
        }
        return typeMap[type] || 'default'
      }
  
      onMounted(() => {
        fetchWithdrawals()
      })
  
      return {
        loading,
        withdrawalsList,
        currentPage,
        pageSize,
        total,
        dialogVisible,
        currentWithdrawal,
        searchForm,
        handleSearch,
        resetSearch,
        handleSizeChange,
        handleCurrentChange,
        viewWithdrawalDetail,
        handleWithdrawal,
        getStatusName,
        getStatusType,
        getAccountTypeName,
        getAccountTypeTag,
        getAmountClass,
        formatAmount
      }
    }
  }
  </script>
  
  <style scoped>
  .distribution-withdrawals {
    padding: 20px;
  }
  
  .filter-card {
    margin-bottom: 20px;
  }
  
  .filter-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .search-form {
    width: 100%;
  }
  
  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .pagination-container {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
  }
  
  .withdrawal-detail {
    margin-bottom: 20px;
  }
  
  .dialog-footer {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
  </style>