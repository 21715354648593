<template>
  <div class="app-version-management">
    <el-card class="mb-4">
      <div class="top-bar">
        <div class="search-area">
          <el-form :inline="true" :model="searchForm" class="search-form">
            <el-form-item label="应用ID">
              <el-input v-model="searchForm.appId" placeholder="应用ID"></el-input>
            </el-form-item>
            <el-form-item label="应用类型">
              <el-select v-model="searchForm.appType" placeholder="选择应用类型">
                <el-option
                  v-for="type in appTypes"
                  :key="type.value"
                  :label="type.label"
                  :value="type.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
        <div class="button-area">
          <el-button type="primary" @click="searchVersions">查询</el-button>
          <el-button @click="resetSearch">重置</el-button>
          <el-button type="success" @click="openUploadDialog">上传新版本</el-button>
        </div>
      </div>
    </el-card>

    <el-card>
      <el-table :data="versions" style="width: 100%">
        <el-table-column prop="app_id" label="应用ID" width="120"></el-table-column>
        <el-table-column prop="version" label="版本号" width="120"></el-table-column>
        <el-table-column prop="app_type" label="应用类型" width="120">
          <template #default="scope">
            {{ getAppTypeName(scope.row.app_type) }}
          </template>
        </el-table-column>
        <el-table-column prop="file_size" label="文件大小" width="120">
          <template #default="scope">
            {{ formatFileSize(scope.row.file_size) }}
          </template>
        </el-table-column>
        <el-table-column prop="release_notes" label="版本说明"></el-table-column>
        <el-table-column prop="created_at" label="创建时间" width="180">
          <template #default="scope">
            {{ formatDate(scope.row.created_at) }}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="250">
          <template #default="scope">
            <el-button 
              size="small" 
              type="primary" 
              @click="downloadApp(scope.row)"
              :disabled="!scope.row.download_url">
              下载
            </el-button>
            <el-button 
              size="small" 
              type="danger" 
              @click="deleteVersion(scope.row)">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <div class="pagination-container">
        <el-pagination v-model:current-page="currentPage" v-model:page-size="pageSize" :total="total"
          :page-sizes="[10, 20, 50, 100]" layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange" @current-change="handleCurrentChange">
        </el-pagination>
      </div>
    </el-card>

    <!-- 上传应用对话框 -->
    <el-dialog :title="'上传应用版本'" v-model="uploadDialogVisible" width="60%">
      <el-form ref="uploadFormRef" :model="uploadForm" :rules="uploadRules" label-width="120px">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="应用ID" prop="appId">
              <el-input v-model="uploadForm.appId" placeholder="请输入应用ID"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="应用类型" prop="appType">
              <el-select v-model="uploadForm.appType" placeholder="请选择应用类型">
                <el-option
                  v-for="type in appTypes"
                  :key="type.value"
                  :label="type.label"
                  :value="type.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="版本号" prop="version">
              <el-input v-model="uploadForm.version" placeholder="请输入版本号，如1.0.0"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="版本说明" prop="releaseNotes">
              <el-input v-model="uploadForm.releaseNotes" type="textarea" :rows="4" placeholder="请输入版本说明"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="应用包" prop="file">
              <file-upload
                v-model="uploadForm.file"
                :file-type="FILE_TYPE.FILE_TYPE_APP_PACKAGE"
                :business-type="FILE_BUSINESS_TYPE.APP_PACKAGE"
                :accept="getAcceptByAppType(uploadForm.appType)"
                :tip="getUploadTipByAppType(uploadForm.appType)"
                :max-size="500"
                :use-direct-upload="true"
                :direct-upload-url="`${apiBaseUrl}/auth/admin/storage/app/upload/file`"
                @on-success="handleFileUploadSuccess"
              ></file-upload>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="uploadDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="submitUpload" :loading="uploading">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { ref, reactive, watch, onMounted } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import { post, del, get } from '@/utils/request'
import FileUpload from '@/components/FileUpload.vue'
import env from '../../.env.js'

// 应用类型枚举
const APP_TYPE = {
  APP_TYPE_UNSPECIFIED: 0,
  APP_TYPE_ANDROID: 1,
  APP_TYPE_IOS: 2,
  APP_TYPE_MAC: 3,
  APP_TYPE_WINDOWS: 4
}

// 添加文件类型和业务类型常量
const FILE_TYPE = {
  FILE_TYPE_APP_PACKAGE: 5
}

const FILE_BUSINESS_TYPE = {
  APP_PACKAGE: 10
}

export default {
  name: 'AppVersionManagement',
  components: {
    FileUpload
  },
  setup() {
    const apiBaseUrl = env.BASE_URL
    const searchForm = reactive({
      appId: '',
      appType: ''
    })
    const versions = ref([])
    const currentPage = ref(1)
    const pageSize = ref(10)
    const total = ref(0)
    const uploadDialogVisible = ref(false)
    const uploading = ref(false)
    const uploadForm = reactive({
      appId: '',
      appType: '',
      version: '',
      releaseNotes: '',
      file: '',
      tempKey: '',
      fileSize: 0,
      checksum: ''
    })
    const uploadFormRef = ref(null)

    // 应用类型选项
    const appTypes = [
      { value: APP_TYPE.APP_TYPE_ANDROID, label: '安卓应用' },
      { value: APP_TYPE.APP_TYPE_IOS, label: 'iOS应用' },
      { value: APP_TYPE.APP_TYPE_MAC, label: 'Mac应用' },
      { value: APP_TYPE.APP_TYPE_WINDOWS, label: 'Windows应用' }
    ]

    // 上传表单验证规则
    const uploadRules = {
      appId: [
        { required: true, message: '请输入应用ID', trigger: 'blur' },
        { min: 2, max: 50, message: '长度在 2 到 50 个字符', trigger: 'blur' }
      ],
      appType: [
        { required: true, message: '请选择应用类型', trigger: 'change' }
      ],
      version: [
        { required: true, message: '请输入版本号', trigger: 'blur' },
        { pattern: /^\d+\.\d+\.\d+$/, message: '版本号格式应为x.y.z', trigger: 'blur' }
      ],
      releaseNotes: [
        { required: true, message: '请输入版本说明', trigger: 'blur' }
      ],
      file: [
        { 
          validator: (rule, value, callback) => {
            if (!uploadForm.tempKey) {
              callback(new Error('请上传应用包'))
            } else {
              callback()
            }
          }, 
          trigger: 'change'
        }
      ]
    }

    // 根据应用类型获取接受的文件类型
    const getAcceptByAppType = (appType) => {
      switch (parseInt(appType)) {
        case APP_TYPE.APP_TYPE_ANDROID:
          return '.apk,.aab'
        case APP_TYPE.APP_TYPE_IOS:
          return '.ipa'
        case APP_TYPE.APP_TYPE_MAC:
          return '.dmg,.pkg'
        case APP_TYPE.APP_TYPE_WINDOWS:
          return '.exe,.msi'
        default:
          return ''
      }
    }

    // 根据应用类型获取上传提示
    const getUploadTipByAppType = (appType) => {
      switch (parseInt(appType)) {
        case APP_TYPE.APP_TYPE_ANDROID:
          return '请上传APK或AAB格式的安卓应用包，大小不超过500MB'
        case APP_TYPE.APP_TYPE_IOS:
          return '请上传IPA格式的iOS应用包，大小不超过500MB'
        case APP_TYPE.APP_TYPE_MAC:
          return '请上传DMG或PKG格式的Mac应用包，大小不超过500MB'
        case APP_TYPE.APP_TYPE_WINDOWS:
          return '请上传EXE或MSI格式的Windows应用包，大小不超过500MB'
        default:
          return '请选择应用类型后上传对应格式的应用包，大小不超过500MB'
      }
    }

    // 获取应用类型名称
    const getAppTypeName = (type) => {
      const appType = appTypes.find(t => t.value === type)
      return appType ? appType.label : '未知类型'
    }

    // 格式化文件大小
    const formatFileSize = (size) => {
      if (!size) return '0 B'
      
      const units = ['B', 'KB', 'MB', 'GB', 'TB']
      let index = 0
      let fileSize = size
      
      while (fileSize >= 1024 && index < units.length - 1) {
        fileSize /= 1024
        index++
      }
      
      return `${fileSize.toFixed(2)} ${units[index]}`
    }

    // 格式化日期
    const formatDate = (timestamp) => {
      if (!timestamp) return ''
      const date = new Date(timestamp * 1000)
      return date.toLocaleString()
    }

    // 查询版本列表
    const searchVersions = async () => {
      try {
        const queryParams = {
          page: currentPage.value,
          page_size: pageSize.value
        }
        
        if (searchForm.appId) {
          queryParams.app_id = searchForm.appId
        }
        
        if (searchForm.appType) {
          queryParams.app_type = searchForm.appType
        }
        
        const res = await get('/auth/storage/app/versions', queryParams)
        
        if (res.code === 200 && res.data) {
          versions.value = res.data.versions || []
          total.value = res.data.total || 0
        } else {
          versions.value = []
          total.value = 0
          ElMessage.warning(res.msg || '获取版本列表失败')
        }
      } catch (error) {
        console.error('获取版本列表失败:', error)
        ElMessage.error('获取版本列表失败')
        versions.value = []
        total.value = 0
      }
    }

    // 重置搜索条件
    const resetSearch = () => {
      Object.assign(searchForm, {
        appId: '',
        appType: ''
      })
      versions.value = []
      total.value = 0
    }

    // 打开上传对话框
    const openUploadDialog = () => {
      uploadDialogVisible.value = true
      Object.assign(uploadForm, {
        appId: searchForm.appId || '',
        appType: searchForm.appType || '',
        version: '',
        releaseNotes: '',
        file: '',
        tempKey: '',
        fileSize: 0,
        checksum: ''
      })
      if (uploadFormRef.value) {
        uploadFormRef.value.resetFields()
      }
    }

    // 修改上传相关的处理逻辑
    const handleFileUploadSuccess = async (response, file) => {
      if (response.code === 200 && response.data) {
        // 同时更新 file 和 tempKey
        uploadForm.file = file.url || response.data.url // 保存文件URL
        uploadForm.tempKey = response.data.temp_key
        uploadForm.fileSize = response.data.file_size
        uploadForm.checksum = response.data.checksum
        
        // 手动触发表单验证
        if (uploadFormRef.value) {
          uploadFormRef.value.validateField('file')
        }
        
        ElMessage.success('文件上传成功，请填写其他信息后提交')
      } else {
        ElMessage.error(response.msg || '上传失败')
      }
    }

    // 修改 submitUpload 方法
    const submitUpload = async () => {
      try {
        await uploadFormRef.value.validate()
        
        uploading.value = true
        
        const submitRes = await post('/auth/admin/storage/app/submit', {
          temp_key: uploadForm.tempKey,
          app_id: uploadForm.appId,
          app_type: parseInt(uploadForm.appType),
          version: uploadForm.version,
          release_notes: uploadForm.releaseNotes
        })
        
        if (submitRes.code === 200 && submitRes.data) {
          ElMessage.success('应用版本提交成功')
          uploadDialogVisible.value = false
          // 无论如何都刷新列表
          searchVersions()
        } else {
          ElMessage.error(submitRes.msg || '提交失败')
        }
      } catch (error) {
        console.error('提交应用版本失败:', error)
        ElMessage.error('提交应用版本失败')
      } finally {
        uploading.value = false
      }
    }

    // 修改下载应用的方法
    const downloadApp = (row) => {
      if (!row.download_url) {
        ElMessage.warning('下载链接不存在')
        return
      }
      
      // 使用新的下载接口
      window.open(`${apiBaseUrl}${row.download_url}`, '_blank')
    }

    // 修改删除版本的方法
    const deleteVersion = (row) => {
      ElMessageBox.confirm(`确定要删除应用 ${row.version} 版本吗？此操作不可恢复！`, '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        try {
          // 修改删除接口的调用方式
          const res = await del(`/auth/admin/storage/app/version/${row.app_id}/${row.version}`, {
            app_type: row.app_type
          })
          
          if (res.code === 200) {
            ElMessage.success('版本删除成功')
            searchVersions()
          } else {
            ElMessage.error(res.msg || '删除失败')
          }
        } catch (error) {
          console.error('删除应用版本失败:', error)
          ElMessage.error('删除应用版本失败')
        }
      }).catch(() => {
        // 用户取消删除操作
      })
    }

    // 分页处理
    const handleSizeChange = (size) => {
      pageSize.value = size
      searchVersions()
    }

    const handleCurrentChange = (page) => {
      currentPage.value = page
      searchVersions()
    }

    // 监听应用类型变化，更新上传提示
    watch(() => uploadForm.appType, () => {
      if (uploadFormRef.value) {
        // 重新验证文件字段
        uploadFormRef.value.validateField('file')
      }
    })

    onMounted(() => {
      // 如果URL中有应用ID参数，自动填充
      const urlParams = new URLSearchParams(window.location.search)
      const appIdParam = urlParams.get('appId')
      const appTypeParam = urlParams.get('appType')
      
      if (appIdParam) {
        searchForm.appId = appIdParam
      }
      if (appTypeParam) {
        searchForm.appType = parseInt(appTypeParam)
      }
      
      // 无论是否有参数，都执行查询
      searchVersions()
    })

    return {
      searchForm,
      versions,
      appTypes,
      currentPage,
      pageSize,
      total,
      uploadDialogVisible,
      uploadForm,
      uploadRules,
      uploadFormRef,
      uploading,
      searchVersions,
      resetSearch,
      openUploadDialog,
      submitUpload,
      downloadApp,
      deleteVersion,
      handleSizeChange,
      handleCurrentChange,
      getAppTypeName,
      formatFileSize,
      formatDate,
      getAcceptByAppType,
      getUploadTipByAppType,
      handleFileUploadSuccess,
      apiBaseUrl,
      FILE_TYPE,
      FILE_BUSINESS_TYPE
    }
  }
}
</script>

<style scoped>
.app-version-management {
  height: 100%;
}

.mb-4 {
  margin-bottom: 1rem;
}

.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.search-area {
  flex-grow: 1;
}

.search-form {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.button-area {
  display: flex;
  gap: 0.5rem;
}

.pagination-container {
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;
}

.avatar-uploader {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 178px;
  height: 178px;
}

.avatar-uploader:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  text-align: center;
  line-height: 178px;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>