import { createRouter, createWebHistory } from 'vue-router';
import Login from '@/views/login.vue';
import Layout from '@/components/Layout.vue';
import TopicManagement from '@/views/TopicManagement.vue';
import ClassifyManagement from '@/views/ClassifyManagement.vue';
import FieldManagement from '@/views/baby/FieldManagement.vue';
import FieldPropertyManagement from '@/views/baby/FieldPropertyManagement.vue';
import BabyFieldManagement from '@/views/baby/BabyFieldManagement.vue';
import UserManagement from '@/views/UserManagement.vue';
import RoleManagement from '@/views/RoleManagement.vue';
import PermissionList from '@/views/PermissionList.vue';
import SystemSettings from '@/views/SystemSettings.vue';
import AppVersionManagement from '@/views/AppVersionManagement.vue';
import OrderManagement from '@/views/OrderManagement.vue';
import SubscriptionManagement from '@/views/SubscriptionManagement.vue';
import DistributionMembers from '@/views/distribution/DistributionMembers.vue';
import DistributionBills from '@/views/distribution/DistributionBills.vue';
import DistributionProfits from '@/views/distribution/DistributionProfits.vue';
import DistributionWithdrawals from '@/views/distribution/DistributionWithdrawals.vue';

const routes = [
  {
    path: '/admin',
    component: Layout,
    name: 'Admin',
    children: [
      {
        path: 'topics',
        name: 'TopicManagement',
        component: TopicManagement
      },
      {
        path: 'classify',
        name: 'ClassifyManagement',
        component: ClassifyManagement
      },
      {
        path: 'users',
        name: 'UserManagement',
        component: UserManagement
      },
      {
        path: 'roles',
        name: 'RoleManagement',
        component: RoleManagement
      },
      {
        path: 'permissions',
        name: 'PermissionList',
        component: PermissionList
      },
      {
        path: 'settings',
        name: 'SystemSettings',
        component: SystemSettings
      },
      {
        path: 'baby/fields',
        name: 'FieldManagement',
        component: FieldManagement,
      },
      {
        path: 'baby/field-properties',
        name: 'FieldPropertyManagement',
        component: FieldPropertyManagement,
      },
      {
        path: 'baby/baby-fields',
        name: 'BabyFieldManagement',
        component: BabyFieldManagement,
      },
      {
        path: 'app-versions',
        name: 'AppVersionManagement',
        component: AppVersionManagement,
      },
      {
        path: 'orders',
        name: 'OrderManagement',
        component: OrderManagement,
        meta: { 
          requiresAuth: true,
          title: '订单管理'
        }
      },
      {
        path: 'subscriptions',
        name: 'SubscriptionManagement',
        component: SubscriptionManagement,
        meta: { 
          requiresAuth: true,
          title: '订阅管理'
        }
      },
      {
        path: 'distribution',
        name: 'Distribution',
        redirect: '/admin/distribution/members',
        children: [
          {
            path: 'members',
            name: 'DistributionMembers',
            component: DistributionMembers,
            meta: { 
              requiresAuth: true,
              title: '分销会员列表'
            }
          },
          {
            path: 'bills',
            name: 'DistributionBills',
            component: DistributionBills,
            meta: {
              requiresAuth: true,
              title: '分销账单查询'
            }
          },
          {
            path: 'profits',
            name: 'DistributionProfits',
            component: DistributionProfits,
            meta: {
              requiresAuth: true,
              title: '分润信息列表'
            }
          },
          {
            path: 'withdrawals',
            name: 'DistributionWithdrawals',
            component: DistributionWithdrawals,
            meta: {
              requiresAuth: true,
              title: '提现申请列表'
            }
          }
        ]
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/',
    name: 'HomePage',
    component: () => import('@/views/home/index.vue'),
    children: [
      {
        path: '',
        name: 'HomeMain',
        component: () => import('@/views/home/HomeMain.vue')
      },
      {
        path: 'products',
        name: 'Products',
        component: () => import('@/views/home/Products.vue')
      }
    ]
  },
  // 添加通配符路由，匹配所有未定义的路径
  {
    path: '/:pathMatch(.*)*',
    redirect: '/'
  }
];
console.log(routes)

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});


// 在这里添加导航守卫
router.beforeEach((to, from, next) => {
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const isLoggedIn = userInfo;

  // 只有访问 /admin 开头的路由才检查登录状态
  if (to.path.startsWith('/admin')) {
    if (!isLoggedIn && to.name !== 'Login') {
      next({ name: 'Login' });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
