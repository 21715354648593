<template>
  <div class="file-upload">
    <el-upload
      :action="uploadUrl"
      :data="uploadData"
      :on-success="handleUploadSuccess"
      :on-error="handleError"
      :before-upload="beforeUpload"
      :file-list="fileList"
      :headers="uploadHeaders"
      :accept="accept"
      :multiple="multiple"
      :limit="limit"
      :on-exceed="handleExceed"
      :on-remove="handleRemove"
      :on-preview="handlePreview"
      :list-type="getListType"
      :class="{ 'hide-upload-button': hideUploadButton }"
    >
      <template #default>
        <div class="upload-trigger">
          <el-icon class="upload-icon">
            <component :is="getUploadIcon" />
          </el-icon>
          <span class="upload-text">{{ getUploadText }}</span>
        </div>
      </template>
      <template #tip>
        <div class="el-upload__tip" v-if="tip">
          {{ tip }}
          <div v-if="multiple" class="drag-tip">可拖拽图片调整顺序</div>
        </div>
      </template>
    </el-upload>

    <!-- 图片排序区域 -->
    <el-row v-if="multiple && fileList.length > 1" class="sort-area">
      <draggable 
        v-model="fileList" 
        item-key="uid"
        @end="handleSort"
        handle=".drag-handle"
      >
        <template #item="{ element }">
          <div class="sort-item">
            <el-image 
              :src="element.url" 
              fit="cover"
              class="sort-image"
            />
            <el-icon class="drag-handle"><Rank /></el-icon>
          </div>
        </template>
      </draggable>
    </el-row>

    <!-- 添加自定义预览插槽 -->
    <div v-if="fileList.length > 0" class="preview-area">
      <slot 
        name="preview" 
        :file="fileList[0]"
        v-if="$slots.preview"
      ></slot>
      <div v-else class="default-preview">
        <el-link 
          v-if="!isImageOrVideo" 
          :href="fileList[0].url" 
          target="_blank"
          type="primary"
        >
          查看文件
        </el-link>
      </div>
    </div>

    <!-- 预览对话框 -->
    <el-dialog v-model="previewVisible" :title="previewTitle">
      <img :src="previewUrl" alt="Preview" style="width: 100%">
    </el-dialog>
  </div>
</template>

<script>
import { ref, computed, watch } from 'vue'
import { 
  Plus, 
  Rank, 
  VideoCamera, 
  Headset, 
  Document,
  Picture 
} from '@element-plus/icons-vue'
import draggable from 'vuedraggable'
import env from '../../.env.js'
import { ElMessage } from 'element-plus'

// 添加文件类型常量
const FILE_TYPE = {
  UNKNOWN: 0,
  IMAGE: 1,
  VIDEO: 2,
  AUDIO: 3,
  DOCUMENT: 4,
  FILE_TYPE_APP_PACKAGE: 5
}

export default {
  name: 'FileUpload',
  components: { 
    Plus,
    Rank,
    VideoCamera,
    Headset,
    Document,
    Picture,
    draggable 
  },
  props: {
    modelValue: {
      type: [Array, String],
      default: () => []
    },
    fileType: {
      type: Number,
      required: true
    },
    businessType: {
      type: Number,
      required: true
    },
    accept: {
      type: String,
      default: ''
    },
    multiple: {
      type: Boolean,
      default: false
    },
    limit: {
      type: Number,
      default: 1
    },
    tip: {
      type: String,
      default: ''
    },
    maxSize: {
      type: Number,
      default: 100
    },
    useDirectUpload: {
      type: Boolean,
      default: false
    },
    directUploadUrl: {
      type: String,
      default: ''
    },
    directUploadData: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ['update:modelValue', 'on-success', 'on-remove', 'on-sort'],
  setup(props, { emit }) {
    const apiBaseUrl = env.BASE_URL
    const fileList = ref([])
    const previewVisible = ref(false)
    const previewUrl = ref('')
    const previewTitle = ref('')

    // 计算是否隐藏上传按钮
    const hideUploadButton = computed(() => {
      return !props.multiple && fileList.value.length >= props.limit
    })

    const uploadHeaders = computed(() => {
      const userInfoString = localStorage.getItem('userInfo')
      const userInfo = userInfoString ? JSON.parse(userInfoString) : null
      return userInfo?.token ? { Authorization: userInfo.token } : {}
    })

    // 计算上传URL
    const uploadUrl = computed(() => {
      return props.useDirectUpload ? props.directUploadUrl : `${apiBaseUrl}/auth/storage/object/upload`
    })

    // 计算上传数据
    const uploadData = computed(() => {
      if (props.useDirectUpload) {
        return props.directUploadData
      }
      return {
        file_type: props.fileType,
        business_type: props.businessType
      }
    })

    const handleUploadSuccess = (response, file) => {
      if (props.useDirectUpload) {
        // 直接使用应用上传接口的响应
        emit('on-success', response, file)
        return
      }

      if (response.code === 200 && response.data) {
        const new_file = {
          name: file.name,
          url: response.data.url,
          uid: file.uid,
          object_key: response.data.object_key,
          size: response.data.size,
          file_type: response.data.file_type,
          business_type: response.data.business_type
        }
        
        // 更新文件列表
        const newFileList = props.multiple ? 
          [...fileList.value, new_file] : 
          [new_file]
        
        fileList.value = newFileList
        
        // 根据 multiple 属性决定发出的值类型
        const emitValue = props.multiple ? 
          newFileList.map(f => f.object_key) : 
          newFileList[0]?.object_key || ''
        
        emit('update:modelValue', emitValue)
        // 发出成功事件，传递完整的文件信息
        emit('on-success', new_file, file, newFileList)

        ElMessage.success('上传成功')
      } else {
        ElMessage.error('上传失败：' + (response.msg || '未知错误'))
        // 从文件列表中移除失败的文件
        const fileIndex = fileList.value.findIndex(f => f.uid === file.uid)
        if (fileIndex > -1) {
          fileList.value.splice(fileIndex, 1)
        }
      }
    }

    const handleError = (error, file) => {
      console.error('上传失败:', error)
      ElMessage.error('文件上传失败，请重试')
      // 从文件列表中移除失败的文件
      const fileIndex = fileList.value.findIndex(f => f.uid === file.uid)
      if (fileIndex > -1) {
        fileList.value.splice(fileIndex, 1)
      }
    }

    const beforeUpload = (file) => {
      // 检查文件类型
      let isValidType = true
      if (props.accept) {
        // 将 accept 字符串分割成数组
        const acceptTypes = props.accept.split(',').map(type => type.trim())
        // 检查文件扩展名或 MIME 类型是否匹配
        isValidType = acceptTypes.some(type => {
          if (type.startsWith('.')) {
            // 检查文件扩展名
            return file.name.toLowerCase().endsWith(type.toLowerCase())
          } else {
            // 检查 MIME 类型
            return file.type.match(type.replace('*', '.*'))
          }
        })
      }

      // 转换文件大小为MB
      const fileSize = file.size / 1024 / 1024
      const isLtMaxSize = fileSize < props.maxSize

      // 检查文件是否重复
      const isDuplicate = fileList.value.some(existingFile => {
        return existingFile.name === file.name && existingFile.size === file.size
      })

      if (!isValidType) {
        ElMessage.error('上传文件类型不正确!')
        return false
      }
      if (!isLtMaxSize) {
        ElMessage.error(`上传文件大小不能超过 ${props.maxSize}MB!`)
        return false
      }
      if (isDuplicate) {
        ElMessage.error('该文件已经上传过了!')
        return false
      }

      return true
    }

    const handleExceed = () => {
      ElMessage.warning(`最多只能上传 ${props.limit} 个文件`)
    }

    const handleRemove = (file, uploadFiles) => {
      fileList.value = uploadFiles.map(f => ({
        url: f.url,
        object_key: f.object_key,
        uid: f.uid,
        name: f.name,
        size: f.size,
        file_type: f.file_type,
        business_type: f.business_type
      }))
      
      // 根据 multiple 属性决定发出的值类型
      const emitValue = props.multiple ? 
        uploadFiles.map(f => f.url) : 
        uploadFiles[0]?.url || ''
      
      emit('update:modelValue', emitValue)
      // 发出移除事件，传递完整的文件信息
      emit('on-remove', file, fileList.value)
    }

    const handlePreview = (file) => {
      previewUrl.value = file.url
      previewTitle.value = file.name
      previewVisible.value = true
    }

    // 处理排序
    const handleSort = () => {
      // 发出排序后的文件列表，包含完整的文件信息
      const sortedFiles = fileList.value.map(file => ({
        url: file.url,
        object_key: file.object_key,
        uid: file.uid,
        name: file.name,
        size: file.size,
        file_type: file.file_type,
        business_type: file.business_type
      }))
      
      // 根据 multiple 属性决定发出的值类型
      const emitValue = props.multiple ? 
        sortedFiles.map(f => f.url) : 
        sortedFiles[0]?.url || ''
      
      emit('update:modelValue', emitValue)
      // 发出排序事件，传递完整的文件信息
      emit('on-sort', sortedFiles)
    }

    // 添加计算属性判断文件类型
    const isImageOrVideo = computed(() => {
      return props.fileType === FILE_TYPE.IMAGE || props.fileType === FILE_TYPE.VIDEO
    })

    // 添加新的计算属性
    const getListType = computed(() => {
      return props.fileType === FILE_TYPE.IMAGE ? 'picture-card' : 'text'
    })

    const getUploadIcon = computed(() => {
      switch (props.fileType) {
        case FILE_TYPE.VIDEO:
          return 'VideoCamera'
        case FILE_TYPE.AUDIO:
          return 'Headset'
        case FILE_TYPE.DOCUMENT:
          return 'Document'
        case FILE_TYPE.IMAGE:
          return 'Picture'
        default:
          return 'Plus'
      }
    })

    const getUploadText = computed(() => {
      switch (props.fileType) {
        case FILE_TYPE.VIDEO:
          return '上传视频'
        case FILE_TYPE.AUDIO:
          return '上传音频'
        case FILE_TYPE.DOCUMENT:
          return '上传文件'
        case FILE_TYPE.IMAGE:
          return '上传图片'
        default:
          return '上传'
      }
    })

    const resetFiles = () => {
      fileList.value = []
    }

    watch(() => props.modelValue, (newVal) => {
      // 只在 modelValue 为 null、undefined 或空数组时清空文件列表
      console.log("修改modelValue",newVal)
      if (newVal === null || newVal === undefined || (Array.isArray(newVal) && newVal.length === 0)) {
        fileList.value = []
      } else if (Array.isArray(newVal) && newVal.length > 0) {
        // 如果有值，且文件列表为空，则设置文件列表
          fileList.value = newVal.map((item, index) => ({
            name: `文件${index + 1}`,
            url: item,
            status: 'success',
            uid: index,
            object_key: item
          }))
      } else if (typeof newVal === 'string' && newVal && fileList.value.length === 0) {
        // 处理单个文件的情况
        fileList.value = [{
          name: '当前文件',
          url: newVal,
          status: 'success',
          uid: 0,
          object_key: newVal
        }]
      }
    }, { immediate: true })

    return {
      apiBaseUrl,
      uploadHeaders,
      fileList,
      uploadData,
      hideUploadButton,
      previewVisible,
      previewUrl,
      previewTitle,
      handleUploadSuccess,
      handleError,
      beforeUpload,
      handleExceed,
      handleRemove,
      handlePreview,
      handleSort,
      isImageOrVideo,
      FILE_TYPE,
      getListType,
      getUploadIcon,
      getUploadText,
      resetFiles,
      uploadUrl
    }
  }
}
</script>

<style scoped>
.file-upload {
  width: 100%;
}

.hide-upload-button :deep(.el-upload--picture-card) {
  display: none;
}

:deep(.el-upload-list--picture-card) {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

:deep(.el-upload-list__item) {
  margin: 0 !important;
}

.drag-tip {
  color: #909399;
  font-size: 12px;
  margin-top: 4px;
}

.sort-area {
  margin-top: 16px;
}

.sort-item {
  display: inline-flex;
  align-items: center;
  margin: 0 8px 8px 0;
  position: relative;
}

.sort-image {
  width: 100px;
  height: 100px;
  border-radius: 4px;
  object-fit: cover;
}

.drag-handle {
  position: absolute;
  right: 8px;
  bottom: 8px;
  padding: 4px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 4px;
  cursor: move;
  color: #409EFF;
  font-size: 18px;
}

.drag-handle:hover {
  background-color: #fff;
  color: #66b1ff;
}

.preview-area {
  margin-top: 16px;
  padding: 8px;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
}

.default-preview {
  padding: 8px;
  text-align: center;
}

.upload-trigger {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.upload-icon {
  font-size: 28px;
  color: #8c939d;
  margin-bottom: 8px;
}

.upload-text {
  font-size: 12px;
  color: #8c939d;
}

/* 非图片类型的上传按钮样式 */
:deep(.el-upload) {
  width: auto;
  height: auto;
}

:deep(.el-upload:not(.el-upload--picture-card)) {
  border: 1px dashed var(--el-border-color);
  border-radius: 6px;
  padding: 20px 30px;
  transition: border-color 0.3s;
}

:deep(.el-upload:not(.el-upload--picture-card):hover) {
  border-color: var(--el-color-primary);
}

/* 文件列表项样式 */
:deep(.el-upload-list__item) {
  transition: all 0.3s;
}

:deep(.el-upload-list__item:hover) {
  background-color: var(--el-color-primary-light-9);
}

:deep(.el-upload-list__item-status-label) {
  right: 5px;
  top: 5px;
}

/* 上传列表中的图标样式 */
:deep(.el-upload-list__item .el-icon) {
  font-size: 20px;
  color: var(--el-color-primary);
}

/* 修改上传按钮样式,使其左对齐 */
:deep(.el-upload) {
  display: block;
  text-align: left;
}

/* 隐藏上传按钮 */
.hide-upload-button :deep(.el-upload--text) {
  display: none;
}

/* 统一文件列表样式 */
:deep(.el-upload-list) {
  margin-top: 10px;
}

:deep(.el-upload-list__item) {
  transition: all 0.3s;
  margin-top: 5px;
}

/* 预览区域样式 */
.preview-area {
  margin-top: 10px;
  padding: 10px;
  border: 1px solid var(--el-border-color-light);
  border-radius: 4px;
}

/* 上传按钮样式统一 */
:deep(.el-upload-list--text) {
  .el-upload-list__item {
    padding: 8px;
    border: 1px solid var(--el-border-color-light);
    border-radius: 4px;
    margin-bottom: 8px;
  }
}
</style>
