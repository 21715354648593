<template>
  <div class="user-management">
    <el-card class="mb-4">
      <div class="top-bar">
        <div class="search-area">
          <el-form :inline="true" :model="searchForm" class="search-form">
            <el-form-item label="用户名">
              <el-input v-model="searchForm.username" placeholder="用户名"></el-input>
            </el-form-item>
            <el-form-item label="手机号">
              <el-input v-model="searchForm.phone" placeholder="手机号"></el-input>
            </el-form-item>
            <el-form-item label="邮箱">
              <el-input v-model="searchForm.email" placeholder="邮箱"></el-input>
            </el-form-item>
            <el-form-item label="角色">
              <el-select v-model="searchForm.role" placeholder="选择角色">
                <el-option 
                  v-for="role in roles" 
                  :key="role.name" 
                  :label="role.display_name" 
                  :value="role.name">
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
        <div class="button-area">
          <el-button type="primary" @click="searchUsers">查询</el-button>
          <el-button @click="resetSearch">重置</el-button>
          <el-button type="success" @click="openCreateDialog">新增用户</el-button>
        </div>
      </div>
    </el-card>

    <el-card>
      <el-table :data="users" style="width: 100%">
        <el-table-column prop="casdoor_id" label="ID" width="120"></el-table-column>
        <el-table-column label="头像" width="80">
          <template #default="scope">
            <el-avatar :size="40"
              :src="scope.row.avatar || 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png'">
            </el-avatar>
          </template>
        </el-table-column>
        <el-table-column prop="username" label="用户名"></el-table-column>
        <el-table-column prop="nickname" label="昵称"></el-table-column>
        <el-table-column prop="phone" label="手机号"></el-table-column>
        <el-table-column prop="email" label="邮箱"></el-table-column>
        <el-table-column prop="identity" label="身份">
          <template #default="scope">
            {{ getRoleName(scope.row.identity) }}
          </template>
        </el-table-column>
        <el-table-column prop="enable" label="状态">
          <template #default="scope">
            <el-tag :type="scope.row.enable ? 'success' : 'danger'">
              {{ scope.row.enable ? '启用' : '禁用' }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="250">
          <template #default="scope">
            <el-button size="small" type="primary" @click="editUser(scope.row)">编辑</el-button>
            <el-button size="small" type="info" @click="viewUserSubscriptions(scope.row)">订阅</el-button>
            <el-button size="small" type="danger" @click="deleteUser(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

      <div class="pagination-container">
        <el-pagination v-model:current-page="currentPage" v-model:page-size="pageSize" :total="total"
          :page-sizes="[10, 20, 50, 100]" layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange" @current-change="handleCurrentChange">
        </el-pagination>
      </div>
    </el-card>

    <!-- 新增/编辑用户对话框 -->
    <el-dialog :title="dialogTitle" v-model="dialogVisible" width="60%">
      <el-form ref="userFormRef" :model="userForm" :rules="rules" label-width="120px">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="用户名" prop="userName">
              <el-input v-model="userForm.userName"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="昵称" prop="nickName">
              <el-input v-model="userForm.nickName"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="密码" prop="password" :rules="passwordRules">
              <el-input v-model="userForm.password" type="password"
                :placeholder="userForm.id ? '不修改请留空' : '请输入密码'"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="手机号" prop="phone">
              <el-input v-model="userForm.phone"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="邮箱" prop="email">
              <el-input v-model="userForm.email"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="生日" prop="birthday">
              <el-date-picker v-model="userForm.birthday" type="date" placeholder="选择生日"
                :default-time="new Date(2000, 0, 1)" value-format="x"></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="性别" prop="gender">
              <el-radio-group v-model="userForm.gender">
                <el-radio label="male">男</el-radio>
                <el-radio label="female">女</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="城市" prop="city">
              <el-cascader v-model="userForm.city" :options="cityOptions" placeholder="请选择城市"></el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="角色" prop="roleId">
              <el-select v-model="userForm.roleId" placeholder="请选择角色">
                <el-option 
                  v-for="role in roles" 
                  :key="role.name" 
                  :label="role.display_name" 
                  :value="role.name">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="邀请码" prop="inviter">
              <el-input v-model="userForm.inviter" placeholder="请输入邀请码"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="头像">
              <el-upload class="avatar-uploader" action="/api/upload" :show-file-list="false"
                :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
                <img v-if="userForm.avatarPath" :src="userForm.avatarPath" class="avatar" />
                <el-icon v-else class="avatar-uploader-icon">
                  <Plus />
                </el-icon>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="账号状态">
              <el-switch v-model="userForm.enable" active-text="启用" inactive-text="禁用"></el-switch>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="submitUser">确 定</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 修改角色管理对话框 -->
    <el-dialog
      v-model="roleDialogVisible"
      title="用户角色管理"
      width="50%"
      :close-on-click-modal="false"
    >
      <div v-loading="roleLoading">
        <!-- 添加身份信息展示 -->
        <div class="identity-section">
          <el-alert
            type="info"
            :closable="false"
            show-icon
          >
            <template #title>
              <span class="identity-title">用户身份：{{ getUserIdentityName(currentUserIdentity) }}</span>
            </template>
            <template #default>
              <p class="identity-desc">身份是用户的基本属性，一个用户只能拥有一个身份。身份在创建用户时确定，需要通过编辑用户信息来修改。</p>
            </template>
          </el-alert>
        </div>

        <el-divider content-position="left">
          <span class="section-title">角色管理</span>
        </el-divider>
        
        <el-alert
          type="info"
          :closable="false"
          class="mb-4"
        >
          <p class="role-desc">角色是用户的扩展权限，一个用户可以同时拥有多个角色，通过分配不同角色来实现灵活的权限管理。</p>
        </el-alert>

        <div class="current-roles">
          <h4>当前角色：</h4>
          <div class="role-tags">
            <el-tag
              v-for="role in currentUserRoles"
              :key="role.id"
              closable
              @close="removeRole(role)"
              class="role-tag"
            >
              {{ role.name }}
            </el-tag>
            <span v-if="currentUserRoles.length === 0" class="no-roles">暂无角色</span>
          </div>
        </div>
        
        <div class="available-roles">
          <h4>可用角色：</h4>
          <el-select
            v-model="selectedRole"
            placeholder="选择要添加的角色"
            class="role-select"
          >
            <el-option
              v-for="role in availableRoles"
              :key="role.id"
              :label="role.name"
              :value="role.id"
            />
          </el-select>
          <el-button type="primary" @click="addRole" :disabled="!selectedRole">
            添加角色
          </el-button>
        </div>
      </div>
    </el-dialog>

    <!-- 用户订阅列表对话框 -->
    <el-dialog
      v-model="subscriptionDialogVisible"
      :title="`用户订阅列表 - ${currentUserInfo.username || ''}`"
      width="80%"
      :close-on-click-modal="false"
    >
      <div v-loading="subscriptionLoading">
        <!-- 搜索表单 -->
        <el-card class="mb-4">
          <div class="search-and-add">
            <el-form :inline="true" :model="subscriptionSearchForm" class="search-form">
              <el-form-item label="订阅ID">
                <el-input v-model="subscriptionSearchForm.subscription_id" placeholder="请输入订阅ID" clearable></el-input>
              </el-form-item>
              <el-form-item label="订阅状态">
                <el-select v-model="subscriptionSearchForm.status" placeholder="请选择状态" clearable>
                  <el-option 
                    v-for="item in subscriptionStatusOptions" 
                    :key="item.value" 
                    :label="item.label" 
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="计划类型">
                <el-select v-model="subscriptionSearchForm.plan_id" placeholder="请选择计划类型" clearable>
                  <el-option 
                    v-for="item in membershipPlanOptions" 
                    :key="item.value" 
                    :label="item.label" 
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="管理员添加">
                <el-select v-model="subscriptionSearchForm.admin_add" placeholder="请选择" clearable>
                  <el-option :value="true" label="是"></el-option>
                  <el-option :value="false" label="否"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="searchUserSubscriptions">查询</el-button>
                <el-button @click="resetSubscriptionSearch">重置</el-button>
              </el-form-item>
            </el-form>
            <el-button type="success" @click="openAddSubscriptionDialog">添加订阅</el-button>
          </div>
        </el-card>

        <!-- 订阅列表 -->
        <el-table
          :data="userSubscriptionList"
          border
          style="width: 100%"
          :header-cell-style="{ background: '#f5f7fa', color: '#606266' }"
        >
          <el-table-column prop="id" label="订阅ID" width="220" show-overflow-tooltip></el-table-column>
          <el-table-column label="管理员添加" width="100">
            <template #default="scope">
              <el-tag :type="scope.row.admin_add ? 'warning' : 'info'">
                {{ scope.row.admin_add ? '是' : '否' }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="display_name" label="订阅名称" min-width="150" show-overflow-tooltip></el-table-column>
          <el-table-column prop="description" label="描述" min-width="150" show-overflow-tooltip></el-table-column>
          <el-table-column label="订阅状态" width="100">
            <template #default="scope">
              <el-tag :type="getSubscriptionStatusType(scope.row.state)">
                {{ getSubscriptionStatusName(scope.row.state) }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column label="自动续费" width="100">
            <template #default="scope">
              <el-tag :type="scope.row.auto_renew ? 'success' : 'info'">
                {{ scope.row.auto_renew ? '是' : '否' }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column label="周期" width="100">
            <template #default="scope">
              <span>{{ getPeriodName(scope.row.period) }}</span>
            </template>
          </el-table-column>
          <el-table-column label="开始时间" width="180">
            <template #default="scope">
              <span>{{ formatDate(scope.row.start_time) }}</span>
            </template>
          </el-table-column>
          <el-table-column label="结束时间" width="180">
            <template #default="scope">
              <span>{{ formatDate(scope.row.end_time) }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="120">
            <template #default="scope">
              <el-button type="primary" size="small" @click="viewSubscriptionDetail(scope.row)">查看</el-button>
            </template>
          </el-table-column>
        </el-table>

        <div class="pagination-container">
          <el-pagination
            v-model:current-page="subscriptionCurrentPage"
            v-model:page-size="subscriptionPageSize"
            :page-sizes="[10, 20, 50, 100]"
            layout="total, sizes, prev, pager, next, jumper"
            :total="subscriptionTotal"
            @size-change="handleSubscriptionSizeChange"
            @current-change="handleSubscriptionCurrentChange"
          >
          </el-pagination>
        </div>
      </div>
    </el-dialog>

    <!-- 订阅详情对话框 -->
    <el-dialog
      v-model="subscriptionDetailDialogVisible"
      title="订阅详情"
      width="700px"
    >
      <div v-if="currentSubscription" class="subscription-detail">
        <el-descriptions :column="2" border>
          <el-descriptions-item label="订阅ID" :span="2">{{ currentSubscription.id }}</el-descriptions-item>
          <el-descriptions-item label="用户ID" :span="2">{{ currentSubscription.user_id }}</el-descriptions-item>
          <el-descriptions-item label="订阅名称">{{ currentSubscription.display_name }}</el-descriptions-item>
          <el-descriptions-item label="订阅状态">
            <el-tag :type="getSubscriptionStatusType(currentSubscription.state)">
              {{ getSubscriptionStatusName(currentSubscription.state) }}
            </el-tag>
          </el-descriptions-item>
          <el-descriptions-item label="自动续费">
            <el-tag :type="currentSubscription.auto_renew ? 'success' : 'info'">
              {{ currentSubscription.auto_renew ? '是' : '否' }}
            </el-tag>
          </el-descriptions-item>
          <el-descriptions-item label="周期">{{ getPeriodName(currentSubscription.period) }}</el-descriptions-item>
          <el-descriptions-item label="开始时间">{{ formatDate(currentSubscription.start_time) }}</el-descriptions-item>
          <el-descriptions-item label="结束时间">{{ formatDate(currentSubscription.end_time) }}</el-descriptions-item>
          <el-descriptions-item label="下次计费时间">{{ formatDate(currentSubscription.next_bill_date) }}</el-descriptions-item>
          <el-descriptions-item label="描述" :span="2">{{ currentSubscription.description }}</el-descriptions-item>
        </el-descriptions>

        <div class="subscription-orders">
          <h3>关联订单</h3>
          <el-table
            :data="relatedOrders"
            border
            style="width: 100%"
            :header-cell-style="{ background: '#f5f7fa', color: '#606266' }"
          >
            <el-table-column prop="id" label="订单ID" width="220" show-overflow-tooltip></el-table-column>
            <el-table-column label="订单状态" width="100">
              <template #default="scope">
                <el-tag :type="getOrderStatusType(scope.row.status)">
                  {{ getOrderStatusName(scope.row.status) }}
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column label="支付金额" width="100">
              <template #default="scope">
                <span>{{ scope.row.payable_amount }} {{ scope.row.currency }}</span>
              </template>
            </el-table-column>
            <el-table-column label="创建时间" width="180">
              <template #default="scope">
                <span>{{ formatDate(scope.row.created_at) }}</span>
              </template>
            </el-table-column>
            <el-table-column label="支付时间" width="180">
              <template #default="scope">
                <span>{{ formatDate(scope.row.paid_at) }}</span>
              </template>
            </el-table-column>
            <el-table-column label="支付方式" width="120">
              <template #default="scope">
                <span>{{ scope.row.payment_method || '未支付' }}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="subscriptionDetailDialogVisible = false">关闭</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 添加订阅对话框 -->
    <el-dialog
      v-model="addSubscriptionDialogVisible"
      title="添加订阅"
      width="600px"
      :close-on-click-modal="false"
    >
      <div v-loading="plansLoading">
        <el-form :model="addSubscriptionForm" :rules="addSubscriptionRules" ref="addSubscriptionFormRef" label-width="120px">
          <el-form-item label="订阅计划" prop="planMethod">
            <el-radio-group v-model="addSubscriptionForm.planMethod">
              <el-radio label="plan">使用现有计划</el-radio>
              <el-radio label="custom">自定义时间</el-radio>
            </el-radio-group>
          </el-form-item>
          
          <!-- 使用现有计划 -->
          <template v-if="addSubscriptionForm.planMethod === 'plan'">
            <el-form-item label="选择计划" prop="planId">
              <el-select v-model="addSubscriptionForm.planId" placeholder="请选择订阅计划" style="width: 100%">
                <el-option
                  v-for="plan in availablePlans"
                  :key="plan.id"
                  :label="`${plan.display_name} (${plan.price}${plan.currency}/${getPeriodName(plan.period)})`"
                  :value="plan.id"
                >
                  <div class="plan-option">
                    <div class="plan-name">{{ plan.display_name }}</div>
                    <div class="plan-price">{{ plan.price }}{{ plan.currency }}/{{ getPeriodName(plan.period) }}</div>
                    <div class="plan-description">{{ plan.description }}</div>
                    <div class="plan-duration">有效期: {{ plan.duration_days }}天</div>
                  </div>
                </el-option>
              </el-select>
            </el-form-item>
          </template>
          
          <!-- 自定义时间 -->
          <template v-else>
            <el-form-item label="快捷选择">
              <el-button-group>
                <el-button @click="selectQuickDuration(1)">1天</el-button>
                <el-button @click="selectQuickDuration(7)">1周</el-button>
                <el-button @click="selectQuickDuration(30)">1个月</el-button>
                <el-button @click="selectQuickDuration(90)">3个月</el-button>
                <el-button @click="selectQuickDuration(180)">6个月</el-button>
                <el-button @click="selectQuickDuration(365)">1年</el-button>
              </el-button-group>
            </el-form-item>
            
            <el-form-item label="开始时间" prop="startTime">
              <el-date-picker
                v-model="addSubscriptionForm.startTime"
                type="datetime"
                placeholder="选择开始时间"
                format="YYYY-MM-DD HH:mm:ss"
                value-format="x"
                style="width: 100%"
              ></el-date-picker>
            </el-form-item>
            
            <el-form-item label="结束时间" prop="endTime">
              <el-date-picker
                v-model="addSubscriptionForm.endTime"
                type="datetime"
                placeholder="选择结束时间"
                format="YYYY-MM-DD HH:mm:ss"
                value-format="x"
                style="width: 100%"
              ></el-date-picker>
            </el-form-item>
          </template>
          
          <el-form-item label="订阅状态" prop="state">
            <el-select v-model="addSubscriptionForm.state" placeholder="请选择状态" style="width: 100%">
              <el-option
                v-for="item in subscriptionStatusOptions.filter(item => item.value !== '')"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          
          <el-form-item label="备注" prop="note">
            <el-input
              v-model="addSubscriptionForm.note"
              type="textarea"
              :rows="3"
              placeholder="请输入备注信息"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="addSubscriptionDialogVisible = false">取消</el-button>
          <el-button type="primary" @click="submitAddSubscription" :loading="addingSubscription">确认添加</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { ref, reactive, watch, onMounted, computed, h } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import { Plus } from '@element-plus/icons-vue'
import { post, put, del, get } from '@/utils/request'
import { USER_IDENTITY_LIST, hasIdentity, isSuperAdmin, ORDER_STATUS, MEMBERSHIP_PLANS, SUBSCRIPTION_STATUS } from '@/utils/constants'
import utils from '@/utils'
import dayjs from 'dayjs'

export default {
  name: 'UserManagement',
  components: {
    Plus,
  },
  setup() {
    const searchForm = reactive({
      username: '',
      phone: '',
      email: '',
      role: ''
    })
    const users = ref([])
    const roles = ref([])
    const allPermissions = ref([])
    const currentPage = ref(1)
    const pageSize = ref(10)
    const total = ref(0)
    const dialogVisible = ref(false)
    const dialogTitle = ref('新增用户')
    const userForm = reactive({
      id: null,
      userName: '',
      password: '',
      phone: '',
      email: '',
      roleId: '',
      nickName: '',
      birthday: '',
      gender: '',
      city: '',
      avatarPath: '',
      enable: true,
      weights: 0,
      status: 0,
      inviter: '',
    })
    const userFormRef = ref(null)

    const rules = {
      userName: [
        { required: true, message: '请输入用户名', trigger: 'blur' },
        { min: 3, max: 20, message: '长度在 3 到 20 个字符', trigger: 'blur' }
      ],
      phone: [
        { pattern: /^1[3-9]\d{9}$/, message: '请输入正确的手机号', trigger: 'blur' }
      ],
      email: [
        { type: 'email', message: '请输入正确的邮箱地址', trigger: 'blur' }
      ],
      roleId: [
        { required: true, message: '请选择角色', trigger: 'change' }
      ],
      nickName: [
        { max: 50, message: '昵称最长50个字符', trigger: 'blur' }
      ]
    }

    const fetchRoles = async () => {
      try {
        const res = await get('/auth/admin/roles')
        if (res?.data?.roles) {
          roles.value = res.data.roles.map(role => ({
            display_name: role.display_name,
            name: role.name
          }))
        } else {
          roles.value = []
        }
      } catch (error) {
        console.error('获取角色列表失败:', error)
        ElMessage.error('获取角色列表失败')
      }
    }

    const searchUsers = async () => {
      try {
        const res = await post('/auth/admin/query/query_users', {
          username: searchForm.username,
          phone: searchForm.phone,
          email: searchForm.email,
          identity: searchForm.role ? searchForm.role : undefined,
          page: currentPage.value,
          size: pageSize.value
        })
        users.value = res.data.list
        total.value = res.data.total
      } catch (error) {
        ElMessage.error('获取用户列表失败')
      }
    }

    const resetSearch = () => {
      Object.assign(searchForm, {
        username: '',
        phone: '',
        email: '',
        role: ''
      })
      searchUsers()
    }

    const resetForm = () => {
      Object.assign(userForm, {
        id: null,
        userName: '',
        password: '',
        phone: '',
        email: '',
        roleId: '',
        nickName: '',
        birthday: '',
        gender: '',
        city: '',
        avatarPath: '',
        enable: true,
        weights: 0,
        status: 0,
        inviter: '',
      })
    }

    const openCreateDialog = async () => {
      dialogTitle.value = '新增用户'
      resetForm()
      await fetchRoles()
      dialogVisible.value = true
    }

    const editUser = async (row) => {
      console.log('编辑的用户数据:', row)
      dialogTitle.value = '编辑用户'
      Object.assign(userForm, {
        id: row.base_info.id,
        userName: row.username,
        password: '',
        phone: row.phone || '',
        email: row.email || '',
        roleId: row.identity || '',
        nickName: row.nickname || '',
        birthday: row.birthday || '',
        gender: row.gender || '',
        city: row.city || '',
        avatarPath: row.avatar || '',
        enable: typeof row.enable === 'boolean' ? row.enable : true,
        weights: row.weights || 0,
        status: row.status || 0,
        inviter: row.inviter || '',
      })
      await fetchRoles()
      dialogVisible.value = true
    }

    const deleteUser = (row) => {
      let forceDelete = false
      
      ElMessageBox.confirm(
        h('div', null, [
          h('p', null, '确定要删除这个用户吗？'),
          h('div', { style: 'margin-top: 10px; display: flex; align-items: center;' }, [
            h('input', {
              type: 'checkbox',
              id: 'force-delete-checkbox',
              style: 'margin-right: 8px;',
              onChange: (event) => {
                forceDelete = event.target.checked
              }
            }),
            h('label', {
              for: 'force-delete-checkbox',
              style: 'color: #f56c6c; cursor: pointer;'
            }, '强制删除（删除所有关联数据）')
          ])
        ]),
        '警告',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          customClass: 'delete-confirm-dialog'
        }
      ).then(async () => {
        try {
          console.log('删除用户，强制删除:', forceDelete)
          await del(`/auth/admin/user/${row.casdoor_id || row.base_info.id}`, { force: forceDelete })
          ElMessage.success('删除成功')
          searchUsers()
        } catch (error) {
          ElMessage.error('删除失败')
        }
      }).catch(() => { })
    }

    const submitUser = async () => {
      const valid = await userFormRef.value.validate()
      if (!valid) return
      
      try {
        const userData = {
          username: userForm.userName,
          nickname: userForm.nickName,
          phone: userForm.phone,
          email: userForm.email,
          identity: userForm.roleId,
          avatar: userForm.avatarPath,
          city: userForm.city,
          birthday: userForm.birthday ? parseInt(userForm.birthday) : 0,
          gender: userForm.gender,
          enable: userForm.enable,
          weights: userForm.weights,
          status: userForm.status,
          open_id: '',
          ic: '',
          inviter: userForm.inviter,
          last_check_in: 0,
          continuous_check_in: 0,
          permission: 0,
          balance: 0,
          last_active_time: 0,
          register_source: 0
        }

        if (userForm.id) {
          console.log('更新用户ID:', userForm.id)
          const updateData = {
            id: userForm.id,
            operator_id: utils.getCurrentUserId(),
            user: userData
          }
          if (userForm.password) {
            updateData.password = userForm.password
          }
          
          await put(`/auth/admin/user/${userForm.id}`, updateData)
          ElMessage.success('更新成功')
        } else {
          console.log('创建新用户')
          const createData = {
            username: userForm.userName,
            password: userForm.password,
            operator_id: utils.getCurrentUserId(),
            user: userData
          }
          
          await post('/auth/admin/user', createData)
          ElMessage.success('创建成功')
        }
        dialogVisible.value = false
        searchUsers()
      } catch (error) {
        console.error('操作失败:', error)
        ElMessage.error('操作失败')
      }
    }

    const canDeleteUser = (user) => {
      return isSuperAdmin() || (hasIdentity(user.identity))
    }

    const getRoleName = (identity) => {
      const role = roles.value.find(r => r.name === identity)
      return role ? role.display_name : '未知角色'
    }

    const handleAvatarSuccess = (res) => {
      userForm.avatarPath = res.data.url
    }

    const beforeAvatarUpload = (file) => {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        ElMessage.error('上传头像图片只能是 JPG/PNG 格式!')
      }
      if (!isLt2M) {
        ElMessage.error('上传头像图片大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    }

    const passwordRules = computed(() => {
      return userForm.id ?
        [
          { min: 6, max: 20, message: '密码长度在 6 到 20 个字符', trigger: 'blur' }
        ] :
        [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 6, max: 20, message: '密码长度在 6 到 20 个字符', trigger: 'blur' }
        ]
    })

    const roleDialogVisible = ref(false)
    const roleLoading = ref(false)
    const currentUserRoles = ref([])
    const availableRoles = ref([])
    const selectedRole = ref('')
    const currentUserId = ref('')
    const currentUserIdentity = ref(0)

    const fetchAllRoles = async () => {
      try {
        const res = await get('/auth/admin/roles')
        if (res?.data?.roles) {
          availableRoles.value = res.data.roles
        }
      } catch (error) {
        console.error('获取角色列表失败:', error)
        ElMessage.error('获取角色列表失败')
      }
    }

    const editUserRoles = async (user) => {
      try {
        roleLoading.value = true
        currentUserId.value = user.base_info.id
        currentUserIdentity.value = user.identity
        
        currentUserRoles.value = user.roles || []
        
        await fetchAllRoles()
        
        roleDialogVisible.value = true
      } catch (error) {
        console.error('初始化角色编辑失败:', error)
        ElMessage.error('初始化角色编辑失败')
      } finally {
        roleLoading.value = false
      }
    }

    const addRole = async () => {
      if (!selectedRole.value) return
      
      try {
        roleLoading.value = true
        await post('/auth/admin/assign_role_to_user', {
          user_id: currentUserId.value,
          role_id: selectedRole.value
        })
        
        const newRole = availableRoles.value.find(r => r.id === selectedRole.value)
        if (newRole) {
          currentUserRoles.value.push(newRole)
        }
        
        selectedRole.value = ''
        ElMessage.success('添加角色成功')
        
        await searchUsers()
      } catch (error) {
        console.error('添加角色失败:', error)
        ElMessage.error('添加角色失败')
      } finally {
        roleLoading.value = false
      }
    }

    const removeRole = async (role) => {
      try {
        roleLoading.value = true
        await post('/auth/admin/revoke_role_from_user', {
          user_id: currentUserId.value,
          role_id: role.id
        })
        
        currentUserRoles.value = currentUserRoles.value.filter(r => r.id !== role.id)
        ElMessage.success('移除角色成功')
        
        await searchUsers()
      } catch (error) {
        console.error('移除角色失败:', error)
        ElMessage.error('移除角色失败')
      } finally {
        roleLoading.value = false
      }
    }

    const getUserIdentityName = (identity) => {
      const identityInfo = USER_IDENTITY_LIST.find(item => item.Value === identity)
      return identityInfo ? identityInfo.Key : '未知身份'
    }

    // 订阅管理相关变量
    const subscriptionDialogVisible = ref(false)
    const subscriptionDetailDialogVisible = ref(false)
    const subscriptionLoading = ref(false)
    const userSubscriptionList = ref([])
    const subscriptionCurrentPage = ref(1)
    const subscriptionPageSize = ref(10)
    const subscriptionTotal = ref(0)
    const currentUserInfo = ref({})
    const currentSubscription = ref(null)
    const relatedOrders = ref([])

    // 订阅搜索表单
    const subscriptionSearchForm = reactive({
      subscription_id: '',
      status: '',
      plan_id: '',
      admin_add: ''
    })

    // 订阅状态选项
    const subscriptionStatusOptions = [
      { value: '', label: '全部状态' },
      { value: SUBSCRIPTION_STATUS.ACTIVE, label: '活跃' },
      { value: SUBSCRIPTION_STATUS.CANCELED, label: '已取消' },
      { value: SUBSCRIPTION_STATUS.EXPIRED, label: '已过期' },
      { value: SUBSCRIPTION_STATUS.PENDING, label: '待处理' },
      { value: SUBSCRIPTION_STATUS.SUSPENDED, label: '已暂停' }
    ]

    // 会员计划选项
    const membershipPlanOptions = [
      { value: '', label: '全部计划' },
      { value: MEMBERSHIP_PLANS.MONTHLY, label: '月度会员' },
      { value: MEMBERSHIP_PLANS.YEARLY, label: '年度会员' }
    ]

    // 查看用户订阅
    const viewUserSubscriptions = (user) => {
      currentUserInfo.value = user
      subscriptionSearchForm.subscription_id = ''
      subscriptionSearchForm.status = ''
      subscriptionSearchForm.plan_id = ''
      subscriptionSearchForm.admin_add = ''
      subscriptionCurrentPage.value = 1
      subscriptionDialogVisible.value = true
      searchUserSubscriptions()
    }

    // 获取用户订阅列表
    const searchUserSubscriptions = async () => {
      if (!currentUserInfo.value || !currentUserInfo.value.base_info || !currentUserInfo.value.base_info.id) {
        ElMessage.warning('用户信息不完整')
        return
      }

      subscriptionLoading.value = true
      try {
        const params = {
          page: subscriptionCurrentPage.value,
          page_size: subscriptionPageSize.value,
          user_id: currentUserInfo.value.casdoor_id || currentUserInfo.value.base_info.id,
          ...subscriptionSearchForm
        }
        
        // 移除空值
        Object.keys(params).forEach(key => {
          if (params[key] === '') {
            delete params[key]
          }
        })
        
        const response = await get('/auth/admin/subscriptions', params)
        if (response.code === 200) {
          userSubscriptionList.value = response.data.subscriptions || []
          subscriptionTotal.value = response.data.total || 0
        } else {
          ElMessage.error(response.msg || '获取用户订阅列表失败')
        }
      } catch (error) {
        console.error('获取用户订阅列表失败:', error)
        ElMessage.error('获取用户订阅列表失败')
      } finally {
        subscriptionLoading.value = false
      }
    }

    // 重置订阅搜索
    const resetSubscriptionSearch = () => {
      Object.keys(subscriptionSearchForm).forEach(key => {
        subscriptionSearchForm[key] = ''
      })
      subscriptionCurrentPage.value = 1
      searchUserSubscriptions()
    }

    // 订阅分页处理
    const handleSubscriptionSizeChange = (size) => {
      subscriptionPageSize.value = size
      searchUserSubscriptions()
    }

    const handleSubscriptionCurrentChange = (page) => {
      subscriptionCurrentPage.value = page
      searchUserSubscriptions()
    }

    // 查看订阅详情
    const viewSubscriptionDetail = async (subscription) => {
      currentSubscription.value = subscription
      subscriptionDetailDialogVisible.value = true
      
      // 获取关联订单
      try {
        const response = await get(`/auth/trade/order?subscription_id=${subscription.id}`)
        if (response.code === 200) {
          relatedOrders.value = response.data.orders || []
        } else {
          ElMessage.warning(response.msg || '获取关联订单失败')
          relatedOrders.value = []
        }
      } catch (error) {
        console.error('获取关联订单失败:', error)
        ElMessage.warning('获取关联订单失败')
        relatedOrders.value = []
      }
    }

    // 格式化日期
    const formatDate = (timestamp) => {
      if (!timestamp) return '未设置'
      return dayjs(timestamp * 1000).format('YYYY-MM-DD HH:mm:ss')
    }

    // 获取订阅状态名称
    const getSubscriptionStatusName = (state) => {
      const statusMap = {
        [SUBSCRIPTION_STATUS.ACTIVE]: '活跃',
        [SUBSCRIPTION_STATUS.CANCELED]: '已取消',
        [SUBSCRIPTION_STATUS.EXPIRED]: '已过期',
        [SUBSCRIPTION_STATUS.PENDING]: '待处理',
        [SUBSCRIPTION_STATUS.SUSPENDED]: '已暂停'
      }
      return statusMap[state] || state
    }

    // 获取订阅状态类型（用于标签颜色）
    const getSubscriptionStatusType = (state) => {
      const typeMap = {
        [SUBSCRIPTION_STATUS.ACTIVE]: 'success',
        [SUBSCRIPTION_STATUS.CANCELED]: 'danger',
        [SUBSCRIPTION_STATUS.EXPIRED]: 'warning',
        [SUBSCRIPTION_STATUS.PENDING]: 'info',
        [SUBSCRIPTION_STATUS.SUSPENDED]: 'info'
      }
      return typeMap[state] || 'info'
    }

    // 获取周期名称
    const getPeriodName = (period) => {
      const periodMap = {
        'Monthly': '月付',
        'Yearly': '年付',
        'Weekly': '周付',
        'Daily': '日付'
      }
      return periodMap[period] || period
    }

    // 获取订单状态名称
    const getOrderStatusName = (status) => {
      const statusMap = {
        [ORDER_STATUS.CREATED]: '已创建',
        [ORDER_STATUS.PAID]: '已支付',
        [ORDER_STATUS.CANCELED]: '已取消',
        [ORDER_STATUS.EXPIRED]: '已过期',
        [ORDER_STATUS.TIMEOUT]: '已超时',
        [ORDER_STATUS.REFUNDED]: '已退款',
        [ORDER_STATUS.FAILED]: '支付失败'
      }
      return statusMap[status] || status
    }

    // 获取订单状态类型（用于标签颜色）
    const getOrderStatusType = (status) => {
      const typeMap = {
        [ORDER_STATUS.CREATED]: 'info',
        [ORDER_STATUS.PAID]: 'success',
        [ORDER_STATUS.CANCELED]: 'danger',
        [ORDER_STATUS.EXPIRED]: 'warning',
        [ORDER_STATUS.TIMEOUT]: 'warning',
        [ORDER_STATUS.REFUNDED]: 'warning',
        [ORDER_STATUS.FAILED]: 'danger'
      }
      return typeMap[status] || 'info'
    }

    // 添加订阅相关变量
    const addSubscriptionDialogVisible = ref(false)
    const addSubscriptionFormRef = ref(null)
    const plansLoading = ref(false)
    const addingSubscription = ref(false)
    const availablePlans = ref([])
    
    const addSubscriptionForm = reactive({
      planMethod: 'plan', // 'plan' 或 'custom'
      planId: '',
      startTime: Date.now(),
      endTime: '',
      state: SUBSCRIPTION_STATUS.ACTIVE,
      note: ''
    })
    
    const addSubscriptionRules = {
      planMethod: [{ required: true, message: '请选择订阅方式', trigger: 'change' }],
      planId: [{ required: true, message: '请选择订阅计划', trigger: 'change' }],
      startTime: [{ required: true, message: '请选择开始时间', trigger: 'change' }],
      endTime: [{ required: true, message: '请选择结束时间', trigger: 'change' }],
      state: [{ required: true, message: '请选择订阅状态', trigger: 'change' }]
    }
    
    // 打开添加订阅对话框
    const openAddSubscriptionDialog = async () => {
      addSubscriptionDialogVisible.value = true
      
      // 重置表单
      addSubscriptionForm.planMethod = 'plan'
      addSubscriptionForm.planId = ''
      addSubscriptionForm.startTime = Date.now()
      addSubscriptionForm.endTime = ''
      addSubscriptionForm.state = SUBSCRIPTION_STATUS.ACTIVE
      addSubscriptionForm.note = ''
      
      // 获取可用计划
      await fetchAvailablePlans()
    }
    
    // 获取可用的订阅计划
    const fetchAvailablePlans = async () => {
      plansLoading.value = true
      try {
        const params = {
          user_id: currentUserInfo.value.base_info.id
        }
        
        const response = await get('/auth/trade/plans', params)
        if (response.code === 200) {
          availablePlans.value = response.data.plans || []
        } else {
          ElMessage.error(response.msg || '获取订阅计划失败')
        }
      } catch (error) {
        console.error('获取订阅计划失败:', error)
        ElMessage.error('获取订阅计划失败')
      } finally {
        plansLoading.value = false
      }
    }
    
    // 快捷选择时间
    const selectQuickDuration = (days) => {
      // 查找用户现有订阅中结束时间最晚的一个
      let latestEndTime = Date.now();
      
      if (userSubscriptionList.value && userSubscriptionList.value.length > 0) {
        // 筛选出活跃状态的订阅
        const activeSubscriptions = userSubscriptionList.value.filter(
          sub => sub.state === SUBSCRIPTION_STATUS.ACTIVE
        );
        
        if (activeSubscriptions.length > 0) {
          // 找出结束时间最晚的订阅
          const latestSubscription = activeSubscriptions.reduce((latest, current) => {
            return (latest.end_time > current.end_time) ? latest : current;
          });
          
          if (latestSubscription && latestSubscription.end_time) {
            // 将时间戳转换为毫秒
            latestEndTime = latestSubscription.end_time * 1000;
            // 如果最晚结束时间已经过期，则使用当前时间
            if (latestEndTime < Date.now()) {
              latestEndTime = Date.now();
            }
          }
        }
      }
      
      // 使用最晚的订阅结束时间作为新订阅的开始时间
      addSubscriptionForm.startTime = latestEndTime;
      // 计算结束时间
      addSubscriptionForm.endTime = latestEndTime + days * 24 * 60 * 60 * 1000;
    }
    
    // 提交添加订阅
    const submitAddSubscription = async () => {
      try {
        await addSubscriptionFormRef.value.validate()
        
        addingSubscription.value = true
        
        const params = {
          user_id: currentUserInfo.value.casdoor_id,
          state: addSubscriptionForm.state,
          note: addSubscriptionForm.note
        }
        
        if (addSubscriptionForm.planMethod === 'plan') {
          params.plan_id = addSubscriptionForm.planId
        } else {
          params.start_time = Math.floor(addSubscriptionForm.startTime / 1000)
          params.end_time = Math.floor(addSubscriptionForm.endTime / 1000)
        }
        
        const response = await post('/auth/admin/subscription/add', params)
        
        if (response.code === 200 && response.data.success) {
          ElMessage.success(response.data.message || '添加订阅成功')
          addSubscriptionDialogVisible.value = false
          
          // 刷新订阅列表
          searchUserSubscriptions()
        } else {
          ElMessage.error(response.msg || response.data.message || '添加订阅失败')
        }
      } catch (error) {
        console.error('添加订阅失败:', error)
        ElMessage.error('添加订阅失败')
      } finally {
        addingSubscription.value = false
      }
    }

    const handleCurrentChange = (page) => {
      currentPage.value = page
    }
    
    const handleSizeChange = (size) => {
      pageSize.value = size
      currentPage.value = 1
    }

    onMounted(() => {
      searchUsers()
    })

    watch([currentPage, pageSize], () => {
      searchUsers()
    })

    return {
      searchForm,
      users,
      roles,
      allPermissions,
      currentPage,
      pageSize,
      total,
      dialogVisible,
      dialogTitle,
      userForm,
      searchUsers,
      resetSearch,
      openCreateDialog,
      editUser,
      deleteUser,
      submitUser,
      canDeleteUser,
      hasIdentity,
      userFormRef,
      rules,
      getRoleName,
      handleAvatarSuccess,
      beforeAvatarUpload,
      resetForm,
      passwordRules,
      roleDialogVisible,
      roleLoading,
      currentUserRoles,
      availableRoles,
      selectedRole,
      editUserRoles,
      addRole,
      removeRole,
      currentUserIdentity,
      getUserIdentityName,
      subscriptionDialogVisible,
      subscriptionDetailDialogVisible,
      subscriptionLoading,
      userSubscriptionList,
      subscriptionCurrentPage,
      subscriptionPageSize,
      subscriptionTotal,
      currentUserInfo,
      currentSubscription,
      relatedOrders,
      subscriptionSearchForm,
      subscriptionStatusOptions,
      membershipPlanOptions,
      viewUserSubscriptions,
      searchUserSubscriptions,
      resetSubscriptionSearch,
      handleSubscriptionSizeChange,
      handleSubscriptionCurrentChange,
      viewSubscriptionDetail,
      formatDate,
      getSubscriptionStatusName,
      getSubscriptionStatusType,
      getPeriodName,
      getOrderStatusName,
      getOrderStatusType,
      addSubscriptionDialogVisible,
      addSubscriptionFormRef,
      plansLoading,
      addingSubscription,
      availablePlans,
      addSubscriptionForm,
      addSubscriptionRules,
      openAddSubscriptionDialog,
      selectQuickDuration,
      submitAddSubscription,
      handleCurrentChange,
      handleSizeChange,
      Plus
    }
  }
}
</script>

<style scoped>
.user-management {
  height: 100%;
}

.mb-4 {
  margin-bottom: 1rem;
}

.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.search-area {
  flex-grow: 1;
}

.search-form {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.button-area {
  display: flex;
  gap: 0.5rem;
}

.pagination-container {
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;
}

.avatar-uploader {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 178px;
  height: 178px;
}

.avatar-uploader:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  text-align: center;
  line-height: 178px;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.current-roles {
  margin-bottom: 20px;
}
.role-tags {
  margin-top: 10px;
}
.role-tag {
  margin-right: 8px;
  margin-bottom: 8px;
}
.available-roles {
  display: flex;
  align-items: center;
  gap: 10px;
}
.role-select {
  width: 200px;
}

/* 添加新的样式 */
.identity-section {
  margin-bottom: 20px;
}

.identity-title {
  font-weight: bold;
  font-size: 14px;
}

.identity-desc, .role-desc {
  margin: 8px 0;
  font-size: 13px;
  color: #666;
  line-height: 1.5;
}

.section-title {
  font-size: 16px;
  font-weight: bold;
  color: #409EFF;
}

.mb-4 {
  margin-bottom: 16px;
}

.no-roles {
  color: #909399;
  font-size: 13px;
  font-style: italic;
}

/* 订阅管理相关样式 */
.subscription-detail {
  margin-bottom: 20px;
}

.subscription-orders {
  margin-top: 20px;
}

.subscription-orders h3 {
  margin-bottom: 15px;
  font-weight: 500;
  color: var(--el-text-color-primary);
}

/* 添加订阅相关样式 */
.search-and-add {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.plan-option {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.plan-name {
  font-weight: bold;
}

.plan-price {
  color: #409EFF;
}

.plan-description {
  font-size: 12px;
  color: #606266;
}

.plan-duration {
  font-size: 12px;
  color: #67c23a;
}
</style>