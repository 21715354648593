<template>
  <div class="distribution-bills">
    <el-card class="filter-card">
      <div class="filter-container">
        <el-form :inline="true" :model="searchForm" class="search-form">
          <el-form-item label="分销员ID">
            <el-input v-model="searchForm.dis_user_id" placeholder="请输入分销员ID" clearable></el-input>
          </el-form-item>
          <el-form-item label="分润类型">
            <el-select v-model="searchForm.user_type" placeholder="请选择分润类型" clearable>
              <el-option label="交易分润" value="trade"></el-option>
              <el-option label="邀请分润" value="invite"></el-option>
              <el-option label="升级分润" value="level"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handleSearch">查询</el-button>
            <el-button @click="resetSearch">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-card>

    <el-card class="table-card">
      <template #header>
        <div class="card-header">
          <span>分销账单列表</span>
        </div>
      </template>

      <el-table
        v-loading="loading"
        :data="billsList"
        border
        style="width: 100%"
      >
        <el-table-column prop="id" label="ID" width="80" align="center"></el-table-column>
        <el-table-column prop="dis_user_id" label="用户标识" width="120" align="center"></el-table-column>
        <el-table-column prop="dis_user_name" label="用户名称" width="120" align="center"></el-table-column>
        <el-table-column prop="identity_type" label="用户类型" width="100" align="center">
          <template #default="scope">
            {{ getUserType(scope.row.identity_type) }}
          </template>
        </el-table-column>
        <el-table-column label="账户总览" min-width="180" align="center">
          <template #default="scope">
            <div>总金额: <span :class="getAmountClass(scope.row.total_amount)">¥{{ scope.row.total_amount }}</span></div>
            <div>可用: <span :class="getAmountClass(scope.row.avaible_amount)">¥{{ scope.row.avaible_amount }}</span></div>
            <div>冻结: <span :class="getAmountClass(scope.row.frozen_amount)">¥{{ scope.row.frozen_amount }}</span></div>
          </template>
        </el-table-column>
        <el-table-column label="交易分润账户" min-width="180" align="center">
          <template #default="scope">
            <div>总金额: <span :class="getAmountClass(scope.row.trade_total_amount)">¥{{ scope.row.trade_total_amount }}</span></div>
            <div>可用: <span :class="getAmountClass(scope.row.trade_avaible_amount)">¥{{ scope.row.trade_avaible_amount }}</span></div>
            <div>冻结: <span :class="getAmountClass(scope.row.trade_frozen_amount)">¥{{ scope.row.trade_frozen_amount }}</span></div>
          </template>
        </el-table-column>
        <el-table-column label="邀请分润账户" min-width="180" align="center">
          <template #default="scope">
            <div>总金额: <span :class="getAmountClass(scope.row.invite_total_amount)">¥{{ scope.row.invite_total_amount }}</span></div>
            <div>可用: <span :class="getAmountClass(scope.row.invite_avaible_amount)">¥{{ scope.row.invite_avaible_amount }}</span></div>
            <div>冻结: <span :class="getAmountClass(scope.row.invite_frozen_amount)">¥{{ scope.row.invite_frozen_amount }}</span></div>
          </template>
        </el-table-column>
        <el-table-column label="升级分润账户" min-width="180" align="center">
          <template #default="scope">
            <div>总金额: <span :class="getAmountClass(scope.row.level_total_amount)">¥{{ scope.row.level_total_amount }}</span></div>
            <div>可用: <span :class="getAmountClass(scope.row.level_avaible_amount)">¥{{ scope.row.level_avaible_amount }}</span></div>
            <div>冻结: <span :class="getAmountClass(scope.row.level_frozen_amount)">¥{{ scope.row.level_frozen_amount }}</span></div>
          </template>
        </el-table-column>
        <el-table-column prop="amount_status" label="状态" width="100" align="center">
          <template #default="scope">
            <el-tag :type="getStatusType(scope.row.amount_status)">
              {{ getStatusText(scope.row.amount_status) }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="add_time" label="添加时间" min-width="180" align="center"></el-table-column>
      </el-table>

      <div class="pagination-container">
        <el-pagination
          v-model:current-page="currentPage"
          v-model:page-size="pageSize"
          :page-sizes="[10, 20, 50, 100]"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
import { ref, reactive, onMounted } from 'vue'
import { ElMessage } from 'element-plus'
import { get } from '@/utils/request'
import { getAmountClass, formatAmount } from '@/utils/amount'
import '@/styles/amount.css'

export default {
  name: 'DistributionBills',
  setup() {
    const loading = ref(false)
    const billsList = ref([])
    const currentPage = ref(1)
    const pageSize = ref(10)
    const total = ref(0)

    const searchForm = reactive({
      dis_user_id: '',
      user_type: ''
    })

    const fetchBills = async () => {
      loading.value = true
      try {
        const params = {
          offset: (currentPage.value - 1) * pageSize.value,
          limit: pageSize.value,
          ...searchForm
        }
        
        Object.keys(params).forEach(key => {
          if (params[key] === '') {
            delete params[key]
          }
        })
        
        const response = await get('/auth/distributor/bills', params)
        if (response.code === 200) {
          billsList.value = response.data.rows || []
          total.value = response.data.total || 0
        } else {
          ElMessage.error(response.msg || '获取分销账单列表失败')
        }
      } catch (error) {
        console.error('获取分销账单列表失败:', error)
        ElMessage.error('获取分销账单列表失败')
      } finally {
        loading.value = false
      }
    }

    const handleSearch = () => {
      currentPage.value = 1
      fetchBills()
    }

    const resetSearch = () => {
      Object.keys(searchForm).forEach(key => {
        searchForm[key] = ''
      })
      currentPage.value = 1
      fetchBills()
    }

    const handleSizeChange = (size) => {
      pageSize.value = size
      fetchBills()
    }

    const handleCurrentChange = (page) => {
      currentPage.value = page
      fetchBills()
    }

    const getStatusType = (status) => {
      const statusMap = {
        '0': 'success',
        '1': 'warning',
        '2': 'danger'
      }
      return statusMap[status] || 'info'
    }

    const getStatusText = (status) => {
      const statusTextMap = {
        '0': '正常',
        '1': '冻结',
        '2': '禁用'
      }
      return statusTextMap[status] || '未知'
    }

    const getUserType = (type) => {
      const typeMap = {
        '0': '普通用户',
        '1': '代理商',
        '2': '分销商'
      }
      return typeMap[type] || '未知'
    }

    onMounted(() => {
      fetchBills()
    })

    return {
      loading,
      billsList,
      currentPage,
      pageSize,
      total,
      searchForm,
      handleSearch,
      resetSearch,
      handleSizeChange,
      handleCurrentChange,
      getStatusType,
      getStatusText,
      getUserType,
      getAmountClass,
      formatAmount
    }
  }
}
</script>

<style scoped>
.distribution-bills {
  padding: 20px;
}

.filter-card {
  margin-bottom: 20px;
}

.filter-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.search-form {
  width: 100%;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pagination-container {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}

.amount-positive {
  color: #67C23A;
  font-weight: bold;
}

.amount-zero {
  color: #909399;
}
</style> 