<template>
  <div class="login-container">
    <div class="login-box">
      <!-- Logo区域 -->
      <div class="login-logo">
        <div class="back-home">
          <el-button link @click="goHome">
            <el-icon><ArrowLeft /></el-icon>返回首页
          </el-button>
        </div>
        <img src="@/assets/logo-light.png" alt="logo" />
        <h2>米鹿儿童测评管理系统</h2>
      </div>

      <!-- 登录表单区域 -->
      <div class="login-form-container">
        <!-- 登录方式切换 -->
        <div class="login-type-switch">
          <span 
            :class="['switch-item', activeType === 'account' ? 'active' : '']"
            @click="activeType = 'account'"
          >账号密码登录</span>
          <span 
            :class="['switch-item', activeType === 'phone' ? 'active' : '']"
            @click="activeType = 'phone'"
          >手机号登录</span>
        </div>

        <!-- 账号密码登录表单 -->
        <el-form
          v-show="activeType === 'account'"
          ref="accountForm"
          :model="accountForm"
          :rules="accountRules"
        >
          <el-form-item prop="username">
            <el-input
              v-model="accountForm.username"
              placeholder="请输入用户名"
              prefix-icon="User"
            />
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              v-model="accountForm.password"
              type="password"
              placeholder="请输入密码"
              prefix-icon="Lock"
              show-password
            />
          </el-form-item>
        </el-form>

        <!-- 手机号登录表单 -->
        <el-form
          v-show="activeType === 'phone'"
          ref="phoneForm"
          :model="phoneForm"
          :rules="phoneRules"
        >
          <el-form-item prop="phone">
            <el-input
              v-model="phoneForm.phone"
              placeholder="请输入手机号"
              prefix-icon="Iphone"
            />
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              v-model="phoneForm.password"
              type="password"
              placeholder="请输入密码"
              prefix-icon="Lock"
              show-password
            />
          </el-form-item>
          <!-- <el-form-item prop="code">
            <div class="code-input">
              <el-input
                v-model="phoneForm.code"
                placeholder="请输入验证码"
                prefix-icon="Message"
              />
              <el-button type="primary" @click="getVerifyCode">获取验证码</el-button>
            </div>
          </el-form-item> -->
        </el-form>

        <!-- 记住密码和登录按钮 -->
        <div class="login-actions">
          <el-checkbox v-model="rememberMe">记住我</el-checkbox>
          <a href="#" class="forget-pwd">忘记密码？</a>
        </div>
        
        <el-button 
          type="primary" 
          class="login-button"
          @click="handleLogin"
        >登录</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { post } from '@/utils/request';
import { ArrowLeft } from '@element-plus/icons-vue'
import { onMounted } from 'vue'
import { useRouter } from 'vue-router'

export default {
  name: 'LoginPage',
  components: {
    ArrowLeft
  },
  data() {
    return {
      activeType: 'account', // 当前登录方式
      accountForm: {
        username: '',
        password: ''
      },
      phoneForm: {
        phone: '',
        password: '',
        code: ''
      },
      rememberMe: false,
      accountRules: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 6, message: '密码长度至少为6个字符', trigger: 'blur' }
        ]
      },
      phoneRules: {
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { pattern: /^1[3-9]\d{9}$/, message: '请输入正确的手机号', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 6, message: '密码长度至少为6个字符', trigger: 'blur' }
        ],
        code: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
          { len: 6, message: '验证码长度应为6位', trigger: 'blur' }
        ]
      }
    }
  },
  setup() {
    const router = useRouter()
    
    onMounted(() => {
      checkLoginStatus()
    })

    const checkLoginStatus = () => {
      const userInfo = JSON.parse(localStorage.getItem('userInfo'))
      if (userInfo && userInfo.token) {
        router.push('/admin')
      }
    }

    const goHome = () => {
      router.push('/')
    }

    return {
      goHome
    }
  },
  mounted() {
    this.loadSavedCredentials();
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.login();
        } else {
          return false;
        }
      });
    },
    async login() {
      try {
        const res = await post('/login', this.loginForm);
        if (res.code === 200) {
          this.saveUserInfo(res.data);
          if (this.rememberMe) {
            this.saveCredentials();
          } else {
            this.clearSavedCredentials();
          }
          
          this.$router.push({ name: 'Home' });
        } else {
          this.$message.error(res.message || '登录失败');
        }
      } catch (err) {
        console.error('登录失败:', err);
      }
    },
    saveUserInfo(data) {
      localStorage.setItem('userInfo', JSON.stringify(data));
    },
    saveCredentials() {
      const form = this.activeType === 'account' ? this.accountForm : this.phoneForm;
      localStorage.setItem('loginType', this.activeType);
      localStorage.setItem('savedUsername', form.username || form.phone);
      localStorage.setItem('savedPassword', form.password);
    },
    loadSavedCredentials() {
      const savedLoginType = localStorage.getItem('loginType') || 'account';
      const savedUsername = localStorage.getItem('savedUsername');
      const savedPassword = localStorage.getItem('savedPassword');
      
      if (savedUsername && savedPassword) {
        this.activeType = savedLoginType;
        if (savedLoginType === 'account') {
          this.accountForm.username = savedUsername;
          this.accountForm.password = savedPassword;
        } else {
          this.phoneForm.phone = savedUsername;
          this.phoneForm.password = savedPassword;
        }
        this.rememberMe = true;
      }
    },
    clearSavedCredentials() {
      localStorage.removeItem('loginType');
      localStorage.removeItem('savedUsername');
      localStorage.removeItem('savedPassword');
    },
    async handleLogin() {
      const formRef = this.activeType === 'account' ? 'accountForm' : 'phoneForm'
      const form = this.activeType === 'account' ? this.accountForm : this.phoneForm
      
      this.$refs[formRef].validate(async (valid) => {
        if (valid) {
          try {
            const url = this.activeType === 'account' ? '/login' : '/login/phone'
            const res = await post(url, form)
            
            if (res.code === 200) {
              this.saveUserInfo(res.data)
              if (this.rememberMe) {
                this.saveCredentials()
              } else {
                this.clearSavedCredentials()
              }
              this.$router.push('/admin')
            } else {
              this.$message.error(res.message || '登录失败')
            }
          } catch (err) {
            console.error('登录失败:', err)
          }
        }
      })
    },

    getVerifyCode() {
      // 实现获取验证码的逻辑
    }
  }
}
</script>

<style scoped>
.login-container {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  position: relative;
}

.login-box {
  width: 420px;
  padding: 40px;
  background: #ffffff !important;
  border-radius: 12px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
}

.login-logo {
  text-align: center;
  margin-bottom: 30px;
}

.login-logo img {
  height: 64px;
  margin-bottom: 16px;
}

.login-logo h2 {
  font-size: 24px;
  color: #333333 !important;
  margin: 0;
}

.login-type-switch {
  display: flex;
  margin-bottom: 24px;
  border-bottom: 1px solid #eee;
}

.switch-item {
  flex: 1;
  text-align: center;
  padding: 12px 0;
  cursor: pointer;
  color: #666;
  transition: all 0.3s;
}

.switch-item.active {
  color: #409EFF !important;
  border-bottom: 2px solid #409EFF;
}

.code-input {
  display: flex;
  gap: 12px;
}

.code-input .el-input {
  flex: 1;
}

.login-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 24px 0;
}

.forget-pwd {
  color: #409EFF;
  text-decoration: none;
}

.login-button {
  width: 100%;
  height: 40px;
  font-size: 16px;
}

.el-input {
  height: 40px;
}

:deep(.el-input__wrapper) {
  background-color: #ffffff !important;
  box-shadow: 0 0 0 1px #dcdfe6 inset !important;
}

:deep(.el-input__wrapper:hover) {
  box-shadow: 0 0 0 1px #409EFF inset !important;
}

:deep(.el-input__inner) {
  background-color: #ffffff !important;
  color: #333333 !important;
}

:deep(.el-input__inner::placeholder) {
  color: #909399;
}

.back-home {
  position: absolute;
  left: 20px;
  top: 20px;

  .el-button {
    font-size: 14px;
    color: #666;
    
    &:hover {
      color: var(--el-color-primary);
    }
    
    .el-icon {
      margin-right: 4px;
    }
  }
}
</style>
