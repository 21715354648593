<template>
  <div class="role-management">
    <el-card class="mb-4">
      <div class="top-bar">
        <div class="search-area">
          <el-form :inline="true" :model="searchForm" class="search-form">
            <el-form-item label="角色名称">
              <el-input 
                v-model="searchForm.name" 
                placeholder="角色名称" 
                @input="validateRoleName(searchForm.name)"
              ></el-input>
              <span v-if="nameError" class="error-message">{{ nameError }}</span>
            </el-form-item>
          </el-form>
        </div>
        <div class="button-area">
          <el-button type="primary" @click="searchRoles">查询</el-button>
          <el-button @click="resetSearch">重置</el-button>
          <el-button type="success" @click="openCreateDialog">新增角色</el-button>
        </div>
      </div>
    </el-card>

    <el-card>
      <el-table :data="roles" style="width: 100%">
        <el-table-column prop="display_name" label="显示名称"></el-table-column>
        <el-table-column prop="name" label="角色名称"></el-table-column>
        <el-table-column prop="description" label="描述"></el-table-column>
        <el-table-column label="状态" width="100">
          <template #default="scope">
            <el-tag :type="scope.row.is_enabled ? 'success' : 'danger'">
              {{ scope.row.is_enabled ? '启用' : '禁用' }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="250">
          <template #default="scope">
            <el-button size="small" @click="editRole(scope.row)">编辑</el-button>
            <el-button 
              size="small" 
              type="danger" 
              @click="deleteRole(scope.row)"
              v-if="isSuperAdmin()"
            >删除</el-button>
            <el-button 
              size="small" 
              type="warning" 
              @click="configurePermissions(scope.row)"
            >配置权限</el-button>
          </template>
        </el-table-column>
      </el-table>

      <div class="pagination-container">
        <el-pagination
          v-model:current-page="currentPage"
          v-model:page-size="pageSize"
          :total="total"
          :page-sizes="[10, 20, 50, 100]"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </el-card>

    <!-- 新增/编辑角色对话框 -->
    <el-dialog :title="dialogTitle" v-model="dialogVisible" width="70%" v-loading="permissionLoading">
      <el-form :model="roleForm" label-width="120px">
        <el-form-item label="角色名称">
          <el-input 
            v-model="roleForm.name" 
            @input="validateRoleName(roleForm.name)"
          ></el-input>
          <span v-if="nameError" class="error-message">{{ nameError }}</span>
        </el-form-item>
        <el-form-item label="显示名称">
          <el-input v-model="roleForm.display_name"></el-input>
        </el-form-item>
        <el-form-item label="描述">
          <el-input type="textarea" v-model="roleForm.description"></el-input>
        </el-form-item>
        <el-form-item label="状态">
          <el-switch
            v-model="roleForm.is_enabled"
            :active-value="true"
            :inactive-value="false"
            active-text="启用"
            inactive-text="禁用"
          />
        </el-form-item>
        <el-form-item label="权限配置" class="permissions-form-item">
          <el-scrollbar height="500px">
            <div class="permissions-container">
              <el-collapse v-model="activeGroups">
                <el-collapse-item 
                  v-for="group in groupedPermissions" 
                  :key="group.permission_type"
                  :name="group.permission_type"
                >
                  <template #title>
                    <div class="module-header">
                      <span>{{ formatPermissionType(group.permission_type) }}</span>
                      <div class="module-actions">
                        <span class="permission-count">
                          已选择 {{ getSelectedCount(group) }}/{{ group.permissions.length }}
                        </span>
                        <el-button
                          link
                          type="primary"
                          @click.stop="toggleGroupSelection(group)"
                        >
                          {{ isGroupSelected(group) ? '取消全选' : '全选' }}
                        </el-button>
                      </div>
                    </div>
                  </template>

                  <div class="permission-grid">
                    <el-checkbox-group v-model="roleForm.permissions">
                      <el-row :gutter="20">
                        <el-col 
                          :span="12" 
                          v-for="permission in group.permissions" 
                          :key="permission.id"
                        >
                          <el-checkbox
                            :label="permission.id"
                            class="permission-item"
                            :title="permission.description"
                          >
                            <div class="permission-info">
                              <div class="permission-header">
                                <span class="permission-name">{{ permission.name }}</span>
                              </div>
                              <div class="permission-path" :title="permission.path">
                                {{ permission.path }}
                              </div>
                            </div>
                          </el-checkbox>
                        </el-col>
                      </el-row>
                    </el-checkbox-group>
                  </div>
                </el-collapse-item>
              </el-collapse>
            </div>
          </el-scrollbar>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false">取消</el-button>
          <el-button type="primary" @click="submitRole" :loading="submitting" :disabled="!!nameError">
            确定
          </el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 配置角色权限对话框 -->
    <el-dialog
      v-model="permissionDialogVisible"
      title="配置权限"
      width="60%"
      :close-on-click-modal="false"
    >
      <div v-loading="permissionLoading" class="permissions-container">
        <el-tabs type="border-card">
          <el-tab-pane v-for="group in groupedPermissions" :key="group.permission_type" :label="getPermissionTypeLabel(group.permission_type)">
            <div class="permission-module">
              <el-checkbox-group v-model="permissionForm.permissions">
                <div class="permission-list">
                  <el-checkbox 
                    v-for="permission in group.permissions" 
                    :key="permission.id" 
                    :label="permission.id"
                  >
                    <div>
                      <div>{{ permission.name }}</div>
                      <div class="permission-description">
                        <span class="permission-path">{{ permission.path }}</span>
                      </div>
                    </div>
                  </el-checkbox>
                </div>
              </el-checkbox-group>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="permissionDialogVisible = false" :disabled="permissionLoading">取消</el-button>
          <el-button type="primary" @click="submitPermissions" :loading="permissionSubmitting">确定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { ref, reactive, watch, onMounted, computed } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import { get, post, put, del } from '@/utils/request'
import { isSuperAdmin } from '@/utils/constants'

export default {
  name: 'RoleManagement',
  components: {
  },
  setup() {
    const searchForm = reactive({
      name: ''
    })
    const roles = ref([])
    const allPermissions = ref([])
    const currentPage = ref(1)
    const pageSize = ref(10)
    const total = ref(0)
    const dialogVisible = ref(false)
    const permissionDialogVisible = ref(false)
    const dialogTitle = ref('新增角色')
    const roleForm = reactive({
      name: '',
      display_name: '',
      description: '',
      is_enabled: true,
      permissions: []
    })
    const permissionForm = reactive({
      roleId: '',
      permissions: []
    })

    const nameError = ref('') // 用于存储角色名称的错误信息

    const isEditing = ref(false) // 用于区分新增和编辑

    const groupedPermissions = computed(() => {
      return allPermissions.value || []
    })

    const validateRoleName = (name) => {
      const regex = /^[a-zA-Z0-9_]+$/; // 只允许字母、数字和下划线
      if (!regex.test(name)) {
        nameError.value = '角色名称只能包含字母、数字和下划线';
      } else {
        nameError.value = '';
      }
    }

    const searchRoles = async () => {
      try {
        permissionLoading.value = true
        const res = await get('/auth/admin/roles', {
          params: {
            name: searchForm.name,
            page: currentPage.value,
            pageSize: pageSize.value
          }
        })
        
        if (res?.data?.roles) {
          roles.value = res.data.roles.map(role => ({
            ...role,
            permissions: role.permissions || [] // 处理权限数组
          }))
          total.value = res.data.roles.length
        } else {
          roles.value = []
          total.value = 0
        }
      } catch (error) {
        console.error('获取角色列表失败:', error)
        ElMessage.error('获取角色列表失败')
        roles.value = []
        total.value = 0
      } finally {
        permissionLoading.value = false
      }
    }

    const resetSearch = () => {
      searchForm.name = ''
      searchRoles()
    }

    const resetForms = () => {
      roleForm.name = ''
      roleForm.display_name = ''
      roleForm.description = ''
      roleForm.is_enabled = true
      roleForm.permissions = []
      
      permissionForm.roleId = ''
      permissionForm.permissions = []
    }

    const openCreateDialog = async () => {
      resetForms()
      dialogTitle.value = '新增角色'
      isEditing.value = false
      await fetchAllPermissions()
      dialogVisible.value = true
    }

    const editRole = async (row) => {
      resetForms()
      dialogTitle.value = '编辑角色'
      isEditing.value = true
      
      // 回填基本信息
      roleForm.name = row.name
      roleForm.display_name = row.display_name
      roleForm.description = row.description
      roleForm.is_enabled = row.is_enabled

      try {
        // 获取所有权限列表
        await fetchAllPermissions()
        
        // 回填权限
        if (row.permissions && Array.isArray(row.permissions)) {
          roleForm.permissions = row.permissions.map(p => p.id)
        } else {
          roleForm.permissions = []
        }
      } catch (error) {
        console.error('获取角色权限失败:', error)
        ElMessage.error('获取角色权限失败')
      }

      dialogVisible.value = true
    }

    const deleteRole = async (row) => {
      try {
        await ElMessageBox.confirm('确定要删除该角色吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        
        await del(`/auth/admin/role/${row.name}`)
        ElMessage.success('删除成功')
        searchRoles() // 刷新角色列表
      } catch (error) {
        if (error !== 'cancel') { // 排除取消操作的错误提示
          console.error('删除角色失败:', error)
          ElMessage.error('删除角色失败')
        }
      }
    }

    const submitRole = async () => {
      try {
        if (!roleForm.name) {
          ElMessage.warning('请输入角色名称')
          return
        }

        const roleData = {
          role: {
            name: roleForm.name,
            display_name: roleForm.display_name,
            description: roleForm.description,
            is_enabled: roleForm.is_enabled
          },
          permission_ids: roleForm.permissions // 添加选中的权限ID数组
        }

        if (isEditing.value) {
          // 编辑角色，调用 put 方法
          await put(`/auth/admin/role/${roleForm.name}`, roleData)
          ElMessage.success('更新角色成功')
        } else {
          // 添加角色，调用 post 方法
          await post('/auth/admin/role', roleData)
          ElMessage.success('创建角色成功')
        }
        
        dialogVisible.value = false
        searchRoles() // 刷新角色列表
      } catch (error) {
        console.error('操作失败:', error)
        ElMessage.error('操作失败')
      }
    }

    const configurePermissions = async (role) => {
      try {
        permissionLoading.value = true
        resetForms()
        permissionForm.roleId = role.name
        
        // 直接使用角色中的权限数据
        if (role.permissions && Array.isArray(role.permissions)) {
          permissionForm.permissions = role.permissions.map(p => p.id)
        }
        
        await fetchAllPermissions()
        permissionDialogVisible.value = true
      } catch (error) {
        console.error('获取角色权限失败:', error)
        ElMessage.error('获取角色权限失败')
      } finally {
        permissionLoading.value = false
      }
    }

    const submitPermissions = async () => {
      try {
        permissionSubmitting.value = true
        
        const currentPerms = await get(`/auth/admin/role_permissions/${permissionForm.roleId}`)
        const currentPermIds = currentPerms.permissions.map(p => p.id)
        
        const toAdd = permissionForm.permissions.filter(id => !currentPermIds.includes(id))
        const toRemove = currentPermIds.filter(id => !permissionForm.permissions.includes(id))

        const promises = []
        
        toAdd.forEach(permissionId => {
          promises.push(post('/auth/admin/assign_permission_to_role', {
            roleId: permissionForm.roleId,
            permissionId
          }))
        })

        toRemove.forEach(permissionId => {
          promises.push(post('/auth/admin/revoke_permission_from_role', {
            roleId: permissionForm.roleId,
            permissionId
          }))
        })

        await Promise.all(promises)
        ElMessage.success('权限配置成功')
        permissionDialogVisible.value = false
      } catch (error) {
        ElMessage.error('权限配置失败')
      } finally {
        permissionSubmitting.value = false
      }
    }

    const handleSizeChange = (val) => {
      pageSize.value = val
      currentPage.value = 1
      searchRoles()
    }

    const handleCurrentChange = (val) => {
      currentPage.value = val
      searchRoles()
    }

    const fetchAllPermissions = async () => {
      try {
        permissionLoading.value = true
        const res = await get('/auth/admin/permissions_by_type')
        console.log(res)
        if (res.data && res.data.groups) {
          allPermissions.value = res.data.groups
        } else {
          allPermissions.value = []
          ElMessage.warning('未获取到权限数据')
        }
      } catch (error) {
        console.error('获取权限列表失败:', error)
        ElMessage.error('获取权限列表失败')
        allPermissions.value = []
      } finally {
        permissionLoading.value = false
      }
    }

    const getPermissionTypeLabel = (type) => {
      const labels = {
        'public': '公共权限',
        'auth': '认证权限',
        'admin': '管理权限'
      }
      return labels[type] || type
    }

    const permissionLoading = ref(false)
    const permissionSubmitting = ref(false)

    onMounted(async () => {
      await Promise.all([
        searchRoles(),
        fetchAllPermissions()
      ])
    })

    watch([currentPage, pageSize], () => {
      searchRoles()
    })

    const formatPermissionType = (type) => {
      const typeMap = {
        'public': '公共权限',
        'auth': '认证权限',
        'admin': '管理权限'
      }
      return typeMap[type] || type
    }

    const isGroupSelected = (group) => {
      return group.permissions.every(permission => 
        roleForm.permissions.includes(permission.id)
      )
    }

    const toggleGroupSelection = (group) => {
      const isSelected = isGroupSelected(group)
      const permissionIds = group.permissions.map(p => p.id)
      
      if (isSelected) {
        roleForm.permissions = roleForm.permissions.filter(
          id => !permissionIds.includes(id)
        )
      } else {
        const newPermissions = new Set([...roleForm.permissions, ...permissionIds])
        roleForm.permissions = Array.from(newPermissions)
      }
    }

    const activeGroups = ref(['admin', 'auth', 'public']) // 默认展开所有分组
    
    const getSelectedCount = (group) => {
      return group.permissions.filter(p => 
        roleForm.permissions.includes(p.id)
      ).length
    }

    return {
      searchForm,
      roles,
      allPermissions,
      currentPage,
      pageSize,
      total,
      dialogVisible,
      permissionDialogVisible,
      dialogTitle,
      roleForm,
      permissionForm,
      searchRoles,
      resetSearch,
      openCreateDialog,
      editRole,
      deleteRole,
      submitRole,
      configurePermissions,
      submitPermissions,
      handleSizeChange,
      handleCurrentChange,
      isSuperAdmin,
      groupedPermissions,
      getPermissionTypeLabel,
      permissionLoading,
      permissionSubmitting,
      fetchAllPermissions,
      formatPermissionType,
      isGroupSelected,
      toggleGroupSelection,
      activeGroups,
      getSelectedCount,
      validateRoleName,
      nameError
    }
  }
}
</script>

<style scoped>
.role-management {
  height: 100%;
}
.mb-4 {
  margin-bottom: 1rem;
}
.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.search-area {
  flex-grow: 1;
}
.search-form {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}
.button-area {
  display: flex;
  gap: 0.5rem;
}
.pagination-container {
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;
}
.permissions-form-item {
  width: 100%;
}

.permissions-container {
  max-height: 600px;
  overflow-y: auto;
}

.permission-module {
  margin-bottom: 20px;
  border: 1px solid #ebeef5;
  border-radius: 4px;
}

.module-title {
  padding: 10px;
  background-color: #f5f7fa;
  border-bottom: 1px solid #ebeef5;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.permission-list {
  padding: 10px;
}

.permission-item {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #f0f0f0;
}

.permission-info {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.permission-name {
  font-weight: bold;
}

.permission-description {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #666;
  font-size: 12px;
}

.permission-path {
  color: #409EFF;
}

.permissions-container {
  padding: 0 10px;
}

.module-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.module-actions {
  display: flex;
  align-items: center;
  gap: 16px;
}

.permission-count {
  font-size: 13px;
  color: var(--el-text-color-secondary);
}

.permission-grid {
  margin-top: 10px;
}

.permission-item {
  margin: 8px 0;
  width: 100%;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.permission-item:hover {
  background-color: var(--el-fill-color-light);
}

.permission-info {
  display: flex;
  flex-direction: column;
  gap: 4px;
  text-align: left;
}

.permission-header {
  display: flex;
  align-items: center;
  gap: 8px;
}

.method-tag {
  min-width: 60px;
  text-align: center;
}

.permission-name {
  font-size: 14px;
  color: var(--el-text-color-primary);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.permission-path {
  font-size: 12px;
  color: var(--el-text-color-secondary);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 68px; /* method-tag width + gap */
}

/* 暗黑模式适配 */
:root[data-theme='dark'] .permission-item {
  border-color: var(--el-border-color-darker);
}

:root[data-theme='dark'] .permission-item:hover {
  background-color: var(--el-fill-color-darker);
}

/* 响应式布局 */
@media screen and (max-width: 1200px) {
  .el-col {
    width: 100% !important;
  }
}

.error-message {
  color: red;
  font-size: 12px;
}
</style>