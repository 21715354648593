<template>
  <div class="subscription-management">
    <el-card class="filter-card">
      <div class="filter-container">
        <el-form :inline="true" :model="searchForm" class="search-form">
          <el-form-item label="订阅ID">
            <el-input v-model="searchForm.subscription_id" placeholder="请输入订阅ID" clearable></el-input>
          </el-form-item>
          <el-form-item label="用户ID">
            <el-input v-model="searchForm.user_id" placeholder="请输入用户ID" clearable></el-input>
          </el-form-item>
          <el-form-item label="订阅状态">
            <el-select v-model="searchForm.state" placeholder="请选择状态" clearable>
              <el-option 
                v-for="item in subscriptionStatusOptions" 
                :key="item.value" 
                :label="item.label" 
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="计划类型">
            <el-select v-model="searchForm.plan" placeholder="请选择计划类型" clearable>
              <el-option 
                v-for="item in membershipPlanOptions" 
                :key="item.value" 
                :label="item.label" 
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="管理员添加">
            <el-select v-model="searchForm.admin_add" placeholder="请选择" clearable>
              <el-option label="是" :value="1"></el-option>
              <el-option label="否" :value="0"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handleSearch">查询</el-button>
            <el-button @click="resetSearch">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-card>

    <el-card class="table-card">
      <template #header>
        <div class="card-header">
          <span>订阅列表</span>
        </div>
      </template>

      <el-table
        v-loading="loading"
        :data="subscriptionList"
        border
        style="width: 100%"
        :header-cell-style="{ background: '#f5f7fa', color: '#606266' }"
      >
        <el-table-column prop="id" label="订阅ID" width="220" show-overflow-tooltip></el-table-column>
        <el-table-column prop="user_id" label="用户ID" width="220" show-overflow-tooltip></el-table-column>
        <el-table-column prop="display_name" label="订阅名称" min-width="150" show-overflow-tooltip></el-table-column>
        <el-table-column prop="description" label="描述" min-width="150" show-overflow-tooltip></el-table-column>
        <el-table-column label="管理员添加" width="100">
          <template #default="scope">
            <el-tag :type="scope.row.admin_add ? 'success' : 'info'">
              {{ scope.row.admin_add ? '是' : '否' }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column label="订阅状态" width="100">
          <template #default="scope">
            <el-tag :type="getSubscriptionStatusType(scope.row.state)">
              {{ getSubscriptionStatusName(scope.row.state) }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column label="自动续费" width="100">
          <template #default="scope">
            <el-tag :type="scope.row.auto_renew ? 'success' : 'info'">
              {{ scope.row.auto_renew ? '是' : '否' }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column label="周期" width="100">
          <template #default="scope">
            <span>{{ getPeriodName(scope.row.period) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="开始时间" width="180">
          <template #default="scope">
            <span>{{ formatDate(scope.row.start_time) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="结束时间" width="180">
          <template #default="scope">
            <span>{{ formatDate(scope.row.end_time) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="下次计费时间" width="180">
          <template #default="scope">
            <span>{{ formatDate(scope.row.next_bill_date) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="240" fixed="right">
          <template #default="scope">
            <el-button type="primary" size="small" @click="viewSubscriptionDetail(scope.row)">查看</el-button>
            <el-button 
              v-if="scope.row.state === 'Active'"
              type="warning" 
              size="small" 
              @click="cancelSubscription(scope.row)">
              暂停订阅
            </el-button>
            <el-button 
              v-if="scope.row.state === 'Suspended'"
              type="success" 
              size="small" 
              @click="resumeSubscription(scope.row)">
              恢复订阅
            </el-button>
            <el-button 
              type="danger" 
              size="small" 
              @click="deleteSubscription(scope.row)">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <div class="pagination-container">
        <el-pagination
          v-model:current-page="currentPage"
          v-model:page-size="pageSize"
          :page-sizes="[10, 20, 50, 100]"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </el-card>

    <!-- 订阅详情对话框 -->
    <el-dialog
      v-model="dialogVisible"
      title="订阅详情"
      width="700px"
    >
      <div v-if="currentSubscription" class="subscription-detail">
        <el-descriptions :column="2" border>
          <el-descriptions-item label="订阅ID" :span="2">{{ currentSubscription.id }}</el-descriptions-item>
          <el-descriptions-item label="用户ID" :span="2">{{ currentSubscription.user_id }}</el-descriptions-item>
          <el-descriptions-item label="订阅名称">{{ currentSubscription.display_name }}</el-descriptions-item>
          <el-descriptions-item label="订阅状态">
            <el-tag :type="getSubscriptionStatusType(currentSubscription.state)">
              {{ getSubscriptionStatusName(currentSubscription.state) }}
            </el-tag>
          </el-descriptions-item>
          <el-descriptions-item label="管理员添加">
            <el-tag :type="currentSubscription.admin_add ? 'success' : 'info'">
              {{ currentSubscription.admin_add ? '是' : '否' }}
            </el-tag>
          </el-descriptions-item>
          <el-descriptions-item label="自动续费">
            <el-tag :type="currentSubscription.auto_renew ? 'success' : 'info'">
              {{ currentSubscription.auto_renew ? '是' : '否' }}
            </el-tag>
          </el-descriptions-item>
          <el-descriptions-item label="周期">{{ getPeriodName(currentSubscription.period) }}</el-descriptions-item>
          <el-descriptions-item label="开始时间">{{ formatDate(currentSubscription.start_time) }}</el-descriptions-item>
          <el-descriptions-item label="结束时间">{{ formatDate(currentSubscription.end_time) }}</el-descriptions-item>
          <el-descriptions-item label="下次计费时间">{{ formatDate(currentSubscription.next_bill_date) }}</el-descriptions-item>
          <el-descriptions-item label="描述" :span="2">{{ currentSubscription.description }}</el-descriptions-item>
        </el-descriptions>

        <div class="subscription-orders">
          <h3>关联订单</h3>
          <el-table
            :data="relatedOrders"
            border
            style="width: 100%"
            :header-cell-style="{ background: '#f5f7fa', color: '#606266' }"
          >
            <el-table-column prop="id" label="订单ID" width="220" show-overflow-tooltip></el-table-column>
            <el-table-column label="订单状态" width="100">
              <template #default="scope">
                <el-tag :type="getOrderStatusType(scope.row.status)">
                  {{ getOrderStatusName(scope.row.status) }}
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column label="支付金额" width="100">
              <template #default="scope">
                <span>{{ scope.row.payable_amount }} {{ scope.row.currency }}</span>
              </template>
            </el-table-column>
            <el-table-column label="创建时间" width="180">
              <template #default="scope">
                <span>{{ formatDate(scope.row.created_at) }}</span>
              </template>
            </el-table-column>
            <el-table-column label="支付时间" width="180">
              <template #default="scope">
                <span>{{ formatDate(scope.row.paid_at) }}</span>
              </template>
            </el-table-column>
            <el-table-column label="支付方式" width="120">
              <template #default="scope">
                <span>{{ scope.row.payment_method || '未支付' }}</span>
              </template>
            </el-table-column>
            <el-table-column label="计划" width="120" show-overflow-tooltip>
              <template #default="scope">
                <span>{{ scope.row.plan?.display_name || '-' }}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false">关闭</el-button>
          <el-button 
            v-if="currentSubscription && currentSubscription.state === 'Active'"
            type="warning" 
            @click="cancelSubscription(currentSubscription)">
            暂停订阅
          </el-button>
          <el-button 
            v-if="currentSubscription && currentSubscription.state === 'Suspended'"
            type="success" 
            @click="resumeSubscription(currentSubscription)">
            恢复订阅
          </el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 取消订阅确认对话框 -->
    <el-dialog
      v-model="cancelDialogVisible"
      title="暂停订阅确认"
      width="400px"
    >
      <div class="cancel-confirmation">
        <p>您确定要暂停此订阅吗？</p>
        <p>订阅ID: {{ currentSubscription?.id }}</p>
        <p>用户ID: {{ currentSubscription?.user_id }}</p>
        <p>暂停后可以随时恢复订阅。</p>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="cancelDialogVisible = false">取消</el-button>
          <el-button type="warning" @click="confirmCancelSubscription">确认暂停</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 恢复订阅确认对话框 -->
    <el-dialog
      v-model="resumeDialogVisible"
      title="恢复订阅确认"
      width="400px"
    >
      <div class="cancel-confirmation">
        <p>您确定要恢复此订阅吗？</p>
        <p>订阅ID: {{ currentSubscription?.id }}</p>
        <p>用户ID: {{ currentSubscription?.user_id }}</p>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="resumeDialogVisible = false">取消</el-button>
          <el-button type="success" @click="confirmResumeSubscription">确认恢复</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 删除订阅确认对话框 -->
    <el-dialog
      v-model="deleteDialogVisible"
      title="删除订阅确认"
      width="400px"
    >
      <div class="delete-confirmation">
        <p>您确定要删除此订阅吗？</p>
        <p class="warning-text">此操作不可恢复，请谨慎操作！</p>
        <p>订阅ID: {{ currentSubscription?.id }}</p>
        <p>用户ID: {{ currentSubscription?.user_id }}</p>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="deleteDialogVisible = false">取消</el-button>
          <el-button type="danger" @click="confirmDeleteSubscription">确认删除</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { ref, reactive, onMounted} from 'vue'
import { useRoute } from 'vue-router'
import { ElMessage } from 'element-plus'
import { get, put, del } from '@/utils/request'
import dayjs from 'dayjs'

// 导入常量
import { 
  ORDER_STATUS, 
  MEMBERSHIP_PLANS,
  SUBSCRIPTION_STATUS
} from '@/utils/constants'

export default {
  name: 'SubscriptionManagement',
  setup() {
    const route = useRoute()
    
    // 状态变量
    const loading = ref(false)
    const subscriptionList = ref([])
    const currentPage = ref(1)
    const pageSize = ref(10)
    const total = ref(0)
    const dialogVisible = ref(false)
    const cancelDialogVisible = ref(false)
    const currentSubscription = ref(null)
    const relatedOrders = ref([])
    const resumeDialogVisible = ref(false)
    const deleteDialogVisible = ref(false)

    // 搜索表单
    const searchForm = reactive({
      subscription_id: '',
      user_id: '',
      state: '',
      plan: '',
      admin_add: ''
    })

    // 订阅状态选项
    const subscriptionStatusOptions = [
      { value: '', label: '全部状态' },
      { value: SUBSCRIPTION_STATUS.ACTIVE, label: '活跃' },
      { value: SUBSCRIPTION_STATUS.CANCELED, label: '已取消' },
      { value: SUBSCRIPTION_STATUS.EXPIRED, label: '已过期' },
      { value: SUBSCRIPTION_STATUS.PENDING, label: '待处理' },
      { value: SUBSCRIPTION_STATUS.SUSPENDED, label: '已暂停' }
    ]

    // 会员计划选项
    const membershipPlanOptions = [
      { value: '', label: '全部计划' },
      { value: MEMBERSHIP_PLANS.MONTHLY, label: '月度会员' },
      { value: MEMBERSHIP_PLANS.YEARLY, label: '年度会员' }
    ]

    // 初始化时检查是否有传入的订阅ID
    onMounted(() => {
      const subscription_id = route.query.subscription_id
      if (subscription_id) {
        searchForm.subscription_id = subscription_id
        fetchSubscriptions()
        // 如果有特定订阅ID，直接查看详情
        if (subscription_id) {
          setTimeout(() => {
            if (subscriptionList.value.length > 0) {
              const subscription = subscriptionList.value.find(s => s.id === subscription_id)
              if (subscription) {
                viewSubscriptionDetail(subscription)
              }
            }
          }, 500)
        }
      } else {
        fetchSubscriptions()
      }
    })

    // 获取订阅列表
    const fetchSubscriptions = async () => {
      loading.value = true
      try {
        const params = {
          page: currentPage.value,
          page_size: pageSize.value,
          ...searchForm
        }
        
        // 移除空值
        Object.keys(params).forEach(key => {
          if (params[key] === '' || params[key] === null) {
            delete params[key]
          }
        })
        
        const response = await get('/auth/admin/subscriptions', params)
        if (response.code === 200) {
          subscriptionList.value = response.data.subscriptions || []
          total.value = response.data.total || 0
        } else {
          ElMessage.error(response.msg || '获取订阅列表失败')
        }
      } catch (error) {
        console.error('获取订阅列表失败:', error)
        ElMessage.error('获取订阅列表失败')
      } finally {
        loading.value = false
      }
    }

    // 查询
    const handleSearch = () => {
      currentPage.value = 1
      fetchSubscriptions()
    }

    // 重置搜索
    const resetSearch = () => {
      Object.keys(searchForm).forEach(key => {
        searchForm[key] = ''
      })
      currentPage.value = 1
      fetchSubscriptions()
    }

    // 分页处理
    const handleSizeChange = (size) => {
      pageSize.value = size
      fetchSubscriptions()
    }

    const handleCurrentChange = (page) => {
      currentPage.value = page
      fetchSubscriptions()
    }

    // 查看订阅详情
    const viewSubscriptionDetail = async (subscription) => {
      currentSubscription.value = subscription
      dialogVisible.value = true
      
      // 获取关联订单
      try {
        const response = await get(`/auth/trade/order?subscription_id=${subscription.id}`)
        if (response.code === 200) {
          relatedOrders.value = response.data.orders || []
        } else {
          ElMessage.warning(response.msg || '获取关联订单失败')
          relatedOrders.value = []
        }
      } catch (error) {
        console.error('获取关联订单失败:', error)
        ElMessage.warning('获取关联订单失败')
        relatedOrders.value = []
      }
    }

    // 取消订阅
    const cancelSubscription = (subscription) => {
      currentSubscription.value = subscription
      cancelDialogVisible.value = true
    }

    // 确认取消订阅
    const confirmCancelSubscription = async () => {
      try {
        const response = await put(`/auth/admin/subscription/${currentSubscription.value.id}`, {
          is_cancel: true
        })
        if (response.code === 200) {
          ElMessage.success('订阅已成功暂停')
          cancelDialogVisible.value = false
          dialogVisible.value = false
          fetchSubscriptions()
        } else {
          ElMessage.error(response.msg || '暂停订阅失败')
        }
      } catch (error) {
        console.error('暂停订阅失败:', error)
        ElMessage.error('暂停订阅失败')
      }
    }

    // 恢复订阅
    const resumeSubscription = (subscription) => {
      currentSubscription.value = subscription
      resumeDialogVisible.value = true
    }

    // 确认恢复订阅
    const confirmResumeSubscription = async () => {
      try {
        const response = await put(`/auth/admin/subscription/${currentSubscription.value.id}`, {
          is_resume: true
        })
        if (response.code === 200) {
          ElMessage.success('订阅已成功恢复')
          resumeDialogVisible.value = false
          dialogVisible.value = false
          fetchSubscriptions()
        } else {
          ElMessage.error(response.msg || '恢复订阅失败')
        }
      } catch (error) {
        console.error('恢复订阅失败:', error)
        ElMessage.error('恢复订阅失败')
      }
    }

    // 删除订阅
    const deleteSubscription = (subscription) => {
      currentSubscription.value = subscription
      deleteDialogVisible.value = true
    }

    // 确认删除订阅
    const confirmDeleteSubscription = async () => {
      try {
        const response = await del(`/auth/admin/subscription/${currentSubscription.value.id}`)
        if (response.code === 200 && response.data.success) {
          ElMessage.success(response.data.message || '订阅已成功删除')
          deleteDialogVisible.value = false
          dialogVisible.value = false
          fetchSubscriptions()
        } else {
          ElMessage.error(response.msg || '删除订阅失败')
        }
      } catch (error) {
        console.error('删除订阅失败:', error)
        ElMessage.error('删除订阅失败')
      }
    }

    // 格式化日期
    const formatDate = (timestamp) => {
      if (!timestamp) return '未设置'
      return dayjs(timestamp * 1000).format('YYYY-MM-DD HH:mm:ss')
    }

    // 获取订阅状态名称
    const getSubscriptionStatusName = (state) => {
      const statusMap = {
        [SUBSCRIPTION_STATUS.ACTIVE]: '活跃',
        [SUBSCRIPTION_STATUS.CANCELED]: '已取消',
        [SUBSCRIPTION_STATUS.EXPIRED]: '已过期',
        [SUBSCRIPTION_STATUS.PENDING]: '待处理',
        [SUBSCRIPTION_STATUS.SUSPENDED]: '已暂停'
      }
      return statusMap[state] || state
    }

    // 获取订阅状态类型（用于标签颜色）
    const getSubscriptionStatusType = (state) => {
      const typeMap = {
        [SUBSCRIPTION_STATUS.ACTIVE]: 'success',
        [SUBSCRIPTION_STATUS.CANCELED]: 'danger',
        [SUBSCRIPTION_STATUS.EXPIRED]: 'warning',
        [SUBSCRIPTION_STATUS.PENDING]: 'info',
        [SUBSCRIPTION_STATUS.SUSPENDED]: 'info'
      }
      return typeMap[state] || 'info'
    }

    // 获取周期名称
    const getPeriodName = (period) => {
      const periodMap = {
        'Monthly': '月付',
        'Yearly': '年付',
        'Weekly': '周付',
        'Daily': '日付'
      }
      return periodMap[period] || period
    }

    // 获取订单状态名称
    const getOrderStatusName = (status) => {
      const statusMap = {
        [ORDER_STATUS.CREATED]: '已创建',
        [ORDER_STATUS.PAID]: '已支付',
        [ORDER_STATUS.CANCELED]: '已取消',
        [ORDER_STATUS.EXPIRED]: '已过期',
        [ORDER_STATUS.TIMEOUT]: '已超时',
        [ORDER_STATUS.REFUNDED]: '已退款',
        [ORDER_STATUS.FAILED]: '支付失败'
      }
      return statusMap[status] || status
    }

    // 获取订单状态类型（用于标签颜色）
    const getOrderStatusType = (status) => {
      const typeMap = {
        [ORDER_STATUS.CREATED]: 'info',
        [ORDER_STATUS.PAID]: 'success',
        [ORDER_STATUS.CANCELED]: 'danger',
        [ORDER_STATUS.EXPIRED]: 'warning',
        [ORDER_STATUS.TIMEOUT]: 'warning',
        [ORDER_STATUS.REFUNDED]: 'warning',
        [ORDER_STATUS.FAILED]: 'danger'
      }
      return typeMap[status] || 'info'
    }

    return {
      loading,
      subscriptionList,
      currentPage,
      pageSize,
      total,
      searchForm,
      dialogVisible,
      cancelDialogVisible,
      currentSubscription,
      relatedOrders,
      resumeDialogVisible,
      deleteDialogVisible,
      subscriptionStatusOptions,
      membershipPlanOptions,
      handleSearch,
      resetSearch,
      handleSizeChange,
      handleCurrentChange,
      viewSubscriptionDetail,
      cancelSubscription,
      confirmCancelSubscription,
      resumeSubscription,
      confirmResumeSubscription,
      deleteSubscription,
      confirmDeleteSubscription,
      formatDate,
      getSubscriptionStatusName,
      getSubscriptionStatusType,
      getPeriodName,
      getOrderStatusName,
      getOrderStatusType
    }
  }
}
</script>

<style scoped>
.subscription-management {
  padding: 20px;
}

.filter-card {
  margin-bottom: 20px;
}

.filter-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.search-form {
  width: 100%;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pagination-container {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}

.subscription-detail {
  margin-bottom: 20px;
}

.subscription-orders {
  margin-top: 20px;
}

.subscription-orders h3 {
  margin-bottom: 15px;
  font-weight: 500;
  color: var(--el-text-color-primary);
}

.cancel-confirmation {
  text-align: center;
  padding: 10px 0;
}

.delete-confirmation {
  text-align: center;
  padding: 10px 0;
}

.warning-text {
  color: #f56c6c;
  font-weight: bold;
  margin: 10px 0;
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}
</style>